import { PLAYERS } from './schedule';

export const matches = [
	{
		id: 0,
		players: [PLAYERS.MITCH, PLAYERS.ALEX_H],
		winner: PLAYERS.MITCH,
		sourceMatches: [],
		round: 1,
	},
	{
		id: 1,
		players: [PLAYERS.CHRIS, PLAYERS.ALEX_A],
		winner: PLAYERS.CHRIS,
		sourceMatches: [],
		round: 1,
	},
	{
		id: 2,
		players: [PLAYERS.JORDAN, PLAYERS.RAY],
		winner: PLAYERS.JORDAN,
		sourceMatches: [],
		round: 1,
	},
	{
		id: 3,
		players: [PLAYERS.TAJ, PLAYERS.ANDREW],
		winner: PLAYERS.TAJ,
		sourceMatches: [],
		round: 1,
	},
	{
		id: 4,
		players: [PLAYERS.ELLE, PLAYERS.MITCH],
		winner: '',
		sourceMatches: [0],
		round: 2,
	},
	{
		id: 5,
		players: [PLAYERS.WILLIE, PLAYERS.CHRIS],
		winner: PLAYERS.WILLIE,
		sourceMatches: [1],
		round: 2,
	},
	{
		id: 6,
		players: [PLAYERS.STEVE, PLAYERS.JORDAN],
		winner: PLAYERS.JORDAN,
		sourceMatches: [2],
		round: 2,
	},
	{
		id: 7,
		players: [PLAYERS.KORY, PLAYERS.TAJ],
		winner: PLAYERS.TAJ,
		sourceMatches: [3],
		round: 2,
	},
	{
		id: 8,
		players: [PLAYERS.WILLIE],
		winner: '',
		sourceMatches: [4, 5],
		round: 3,
	},
	{
		id: 9,
		players: [PLAYERS.JORDAN, PLAYERS.TAJ],
		winner: PLAYERS.TAJ,
		sourceMatches: [6, 7],
		round: 3,
	},
	{
		id: 10,
		players: [],
		winner: '',
		sourceMatches: [8, 9],
		round: 4,
	},
];
