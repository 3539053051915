import PropTypes, { arrayOf } from "prop-types";
import AdvacementType from "./advancement";
import StatsType from "./stats";
import { createStats } from "./stats";
import uid from "../services/uid";

const player = PropTypes.shape({
  id: PropTypes.string.isRequired,
  MA: PropTypes.number.isRequired,
  ST: PropTypes.number.isRequired,
  AG: PropTypes.number.isRequired,
  PA: PropTypes.number.isRequired,
  AV: PropTypes.number.isRequired,
  SPP: PropTypes.number.isRequired,
  MNG: PropTypes.bool.isRequired,
  nigglingInjuries: PropTypes.number.isRequired,
  injuryHistory: PropTypes.array.isRequired,
  baseCost: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  advancements: arrayOf(AdvacementType),
  lastUpdatedAt: PropTypes.number.isRequired,
  level: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  primary: arrayOf(PropTypes.string).isRequired,
  secondary: arrayOf(PropTypes.string).isRequired,
  skills: arrayOf(PropTypes.string),
  leagues: arrayOf(PropTypes.string),
  stats: arrayOf(StatsType),
  factionId: PropTypes.string.isRequired,
  rosterId: PropTypes.string.isRequired,
});

export const createPlayer = (basePlayerData, rosterId, factionId) => ({
  id: uid(),
  MA: basePlayerData.MA,
  ST: basePlayerData.ST,
  AG: basePlayerData.AG,
  PA: basePlayerData.PA,
  AV: basePlayerData.AV,
  SPP: 0,
  MNG: false,
  nigglingInjuries: 0,
  injuryHistory: {
    AV: 0,
    AG: 0,
    PA: 0,
    ST: 0,
    MA: 0,
  },
  baseCost: basePlayerData.cost || basePlayerData.baseCost,
  value: basePlayerData.cost || basePlayerData.baseCost,
  advancements: [],
  lastUpdatedAt: Date.now(),
  level: -1,
  max: basePlayerData.max,
  min: basePlayerData.min,
  name: "",
  number: "00",
  position: basePlayerData.position,
  primary: basePlayerData.primary,
  secondary: basePlayerData.secondary,
  skills: basePlayerData.skills.split(","),
  stats: createStats(),
  factionId,
  rosterId,
});

export default player;
