import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	Container,
	Stepper,
	Step,
	StepLabel,
	Button,
	Typography,
	Grid,
	List,
	ListItem,
	ListItemText,
	Divider,
} from '@mui/material';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useAuth } from '../../contexts/AuthContext';
import { database } from '../../services/fire';
import FormHarness from '../../components/FormControls/Harness';
import SelectField from '../../components/FormControls/Select';
import TextInput from '../../components/FormControls/TextInput';
import Loader from '../../components/Loader';
import Error from '../../components/Error';
import createId from '../../services/uid';
import { parseCost } from '../../services/textParser';
import { createPlayer } from '../../types/player';
import { createNewRoster } from '../../types/roster';
import { saveRoster } from '../../services/rosters';
import logEvent from '../../services/eventlog';

const TeamCreation = () => {
	const { user } = useAuth();
	const history = useHistory();

	const [teams, teamsLoading, teamsErrors] = useCollection(
		database.collection('teams')
	);
	const [players, playersLoading, playersErrors] = useCollection(
		database.collection('players')
	);
	const [staff, staffLoading, staffErrors] = useCollection(
		database.collection('staff')
	);
	const [leagues, leaguesLoading, leagueErrors] = useCollection(
		database.collection('leagues')
	);

	const [activeStep, setActiveStep] = useState(0);

	const rosterId = createId();
	const [selectedLeague, setSelectedLeague] = useState();
	const [selectedTeam, setSelectedTeam] = useState({});
	const [selectedPlayers, setSelectedPlayers] = useState([]);
	const [selectedStaff, setSelectedStaff] = useState([]);

	// Handle loading and error states
	if (teamsLoading || playersLoading || staffLoading || leaguesLoading)
		return <Loader />;
	if (teamsErrors) return <Error message={teamsErrors.message} dismissable />;
	if (playersErrors)
		return <Error message={playersErrors.message} dismissable />;
	if (staffErrors) return <Error message={staffErrors.message} dismissable />;
	if (leagueErrors) return <Error message={leagueErrors.message} dismissable />;

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleJumpToStep = (stepIndex) => {
		setActiveStep(stepIndex);
	};

	const handleTeamSelect = (formData) => {
		const team = teams.docs.find((team) => team.data().name === formData.team);
		const selectedTeamData = team.data();
		const selectedTeamId = team.id;
		const league = leagues.docs.find((league) => league.id === formData.league);
		const leagueData = league.data();
		const leagueId = league.id;

		setSelectedLeague({
			...leagueData,
			id: leagueId,
		});

		setSelectedTeam({
			...selectedTeamData,
			id: rosterId,
			factionId: selectedTeamId,
			factionName: selectedTeamData.name,
			teamName: formData.teamName,
			availablePlayers: players.docs
				.filter((player) => {
					const { team } = player.data();
					return team === `${selectedTeamData.name} Teams`;
				})
				.map((player) => ({
					...player.data(),
					factionId: selectedTeamId,
				})),
			leagueId: formData.league,
			treasury: league.rosterStartingValue,
		});
		setSelectedPlayers([]);
		setSelectedStaff([]);
		handleNext();
	};

	const handlePlayerSelect = (formData) => {
		const startingPlayers = [];
		Object.entries(formData).forEach(([key, value]) => {
			const qty = parseInt(value);
			const playerData = selectedTeam.availablePlayers.find(
				({ position }) => position === key
			);
			Array.from(Array(qty).keys()).forEach(() =>
				startingPlayers.push(
					createPlayer(playerData, rosterId, selectedTeam.factionId)
				)
			);
		});
		setSelectedPlayers(startingPlayers);
		handleNext();
	};

	const handleStaffSelect = (formData) => {
		const staffItems = Object.entries(formData).map(([key, value]) => ({
			label: key,
			qty: parseInt(value) || 0,
			value: !key.localeCompare('rerolls')
				? selectedTeam.rerolls.cost
				: staff.docs.find((s) => !s.data().label.localeCompare(key)).data()
						.preseasonCost,
		}));
		setSelectedStaff(staffItems);
		handleNext();
	};

	const handleCreateNewRoster = () => {
		const roster = createNewRoster(
			selectedLeague,
			selectedTeam,
			selectedPlayers,
			selectedStaff,
			user.uid
		);
		if (roster.valid) {
			saveRoster(roster);
			history.push('/team');
			logEvent(
				'ROSTER_ADDED',
				`The ${roster.teamName} have joined the league.`
			);
		}
	};

	const TeamSelectStep = () => {
		return (
			<FormHarness handleFormSubmit={handleTeamSelect}>
				<Grid container spacing={1}>
					<Grid item xs={12} md={6}>
						<TextInput
							fieldName="teamName"
							label="Team Name"
							fullWidth
							defaultValue={selectedTeam?.teamName}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<SelectField
							fieldName="league"
							label="Select a League to Join"
							options={leagues.docs.map((league) => ({
								label: league.data().name,
								value: league.id,
							}))}
							fullWidth
							labelType="standalone"
							defaultValue={selectedLeague?.id}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<SelectField
							fieldName="team"
							label="Select a Team"
							options={teams.docs
								.sort((a, b) => {
									const docA = a.data();
									const docB = b.data();
									if (docA.name > docB.name) return 1;
									if (docA.name < docB.name) return -1;
									return 0;
								})
								.map((doc) => ({
									label: doc.data().name,
									value: doc.data().name,
								}))}
							fullWidth
							labelType="standalone"
							defaultValue={selectedTeam?.name}
						/>
					</Grid>
				</Grid>
				<Button
					variant="contained"
					type="submit"
					fullWidth
					sx={{ marginTop: 1 }}
				>
					Next
				</Button>
			</FormHarness>
		);
	};

	const DraftPlayersStep = () => {
		return (
			<FormHarness handleFormSubmit={handlePlayerSelect}>
				<Grid container spacing={1}>
					{selectedTeam.availablePlayers.map((playerType) => (
						<Grid item xs={12} key={playerType.position}>
							<SelectField
								fieldName={playerType.position}
								label={`${playerType.position} (${parseCost(playerType.cost)})`}
								options={Array.from(Array(playerType.max + 1).keys()).map(
									(val) => ({
										label: val,
										value: val,
									})
								)}
								fullWidth
								labelType="standalone"
								defaultValue={selectedPlayers.reduce((acc, cur) => {
									if (cur.position === playerType.position) return acc + 1;
									return acc;
								}, 0)}
							/>
						</Grid>
					))}
				</Grid>
				<Button
					variant="contained"
					type="button"
					fullWidth
					onClick={handleBack}
					sx={{ marginBottom: 1 }}
					color="secondary"
				>
					Back
				</Button>
				<Button variant="contained" type="submit" fullWidth>
					Next
				</Button>
			</FormHarness>
		);
	};

	const HireStaffStep = () => (
		<FormHarness handleFormSubmit={handleStaffSelect}>
			<Grid container spacing={1}>
				<Grid item xs={12} md={6}>
					<SelectField
						fieldName="rerolls"
						label={`Team Re-Rolls (${parseCost(selectedTeam.rerolls.cost)})`}
						options={Array.from(Array(9).keys()).map((val) => ({
							label: val,
							value: val,
						}))}
						fullWidth
						labelType="standalone"
						defaultValue={
							selectedStaff.find((item) => item.label === 'rerolls')?.qty
						}
					/>
				</Grid>
				{staff.docs.map((type) => {
					const { label, max, cost } = type.data();
					return (
						<Grid item xs={12} md={6} key={label}>
							<SelectField
								fieldName={label}
								label={`${label} (${parseCost(cost)})`}
								options={Array.from(Array(max + 1).keys()).map((val) => ({
									label: val,
									value: val,
								}))}
								fullWidth
								labelType="standalone"
								defaultValue={
									selectedStaff.find((item) => item.label === label)?.qty
								}
							/>
						</Grid>
					);
				})}
			</Grid>
			<Button
				variant="contained"
				type="button"
				fullWidth
				onClick={handleBack}
				sx={{ marginBottom: 1 }}
				color="secondary"
			>
				Back
			</Button>
			<Button variant="contained" type="submit" fullWidth>
				Next
			</Button>
		</FormHarness>
	);

	const ConfirmStep = () => {
		return (
			<List>
				<ListItem>
					<ListItemText primary={selectedTeam.teamName} secondary="Team Name" />
				</ListItem>
				<ListItem>
					<ListItemText primary={selectedTeam.name} secondary="Faction" />
				</ListItem>
				{selectedTeam.availablePlayers.map(({ position }) => (
					<ListItem key={position}>
						<ListItemText
							primary={position}
							secondary={selectedPlayers.reduce(
								(acc, player) =>
									!player.position.localeCompare(position) ? acc + 1 : acc,
								0
							)}
						/>
					</ListItem>
				))}
				{selectedStaff.map((staff) => (
					<ListItem key={staff.label}>
						<ListItemText primary={staff.label} secondary={staff.qty} />
					</ListItem>
				))}
				<Button
					variant="contained"
					type="button"
					fullWidth
					onClick={handleBack}
					sx={{ marginBottom: 1 }}
					color="secondary"
				>
					Go Back
				</Button>
				<Button
					variant="contained"
					type="submit"
					fullWidth
					onClick={handleCreateNewRoster}
					sx={{ marginBottom: 1 }}
				>
					Looks Good, Create My Team
				</Button>
			</List>
		);
	};

	const steps = [
		{
			label: 'Team',
			header: 'Select Your Team',
			value: <TeamSelectStep />,
		},
		{
			label: 'Players',
			header: 'Build Your Roster',
			value: <DraftPlayersStep />,
		},
		{
			label: 'Staff',
			header: 'Add Support Staff',
			value: <HireStaffStep />,
		},
		{
			label: 'Confirm',
			header: 'Everything Look Okay?',
			value: <ConfirmStep />,
		},
	];

	return (
		<Container maxWidth="md" sx={{ flex: 1 }}>
			<Stepper activeStep={activeStep} sx={{ marginBottom: 2 }}>
				{steps.map((step, index) => (
					<Step key={step.label} onClick={() => handleJumpToStep(index)}>
						<StepLabel>{step.label}</StepLabel>
					</Step>
				))}
			</Stepper>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Typography gutterBottom variant="h5" component="div">
						{steps[activeStep].header}
					</Typography>
					{activeStep > 0 && (
						<Typography variant="body2" sx={{ marginBottom: 1 }}>
							Team Value:{' '}
							{parseCost(
								selectedPlayers.reduce((acc, cur) => cur.baseCost + acc, 0) +
									selectedStaff.reduce(
										(acc, cur) => cur.qty * cur.value + acc,
										0
									)
							)}
						</Typography>
					)}
					<Divider sx={{ marginBottom: 3 }} />
					{steps[activeStep].value}
				</Grid>
			</Grid>
		</Container>
	);
};

export default TeamCreation;
