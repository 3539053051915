import React from 'react';
import { useHistory, Prompt } from 'react-router-dom';
import { Container, Stepper, Step, StepLabel, Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { database } from '../../services/fire';
import { useRoster } from '../../contexts/RosterContext';
import { submitMatchSlip } from '../../services/rosters';
import Pregame from '../../components/MatchEditor/Pregame';
import PlayerStats from '../../components/MatchEditor/PlayerStats';
import Postgame from '../../components/MatchEditor/Postgame';
import MatchSummaryDialog from '../../components/MatchEditor/MatchSummaryDialog';
import Loading from '../../components/Loader';
import Error from '../../components/Error';

const steps = ['Pregame', 'Player Stats', 'Postgame'];

const MatchDetail = () => {
	const history = useHistory();
	const navigate = (to) => history.push(to);
	const [blockedNavigation, setBlockedNavigation] = React.useState(true);
	const { ownRosters } = useRoster();
	const { matchId } = useParams();
	const [roster, setRoster] = React.useState(ownRosters[0]);
	const handleUpdateRoster = (newRoster) => {
		setRoster(newRoster);
		handleNext();
	};
	const [activeStep, setActiveStep] = React.useState(0);
	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};
	const [matchResults, setMatchResults] = React.useState();
	const [matchSummary, setShowMatchSummary] = React.useState(false);
	const showMatchSummary = () => {
		setShowMatchSummary(true);
	};
	const hideMatchSummary = () => {
		setShowMatchSummary(false);
		navigate('/matchup');
	};

	// Get Matches
	const [matches, matchesLoading, matchesError] = useCollectionDataOnce(
		database.collection('matches').where('id', '==', matchId),
		{ idField: 'id' }
	);

	// Get Rosters
	const [rosters, rostersLoading, rostersError] = useCollectionDataOnce(
		database.collection('rosters3'),
		{ idField: 'id' }
	);

	if (matchesLoading || rostersLoading) return <Loading />;
	if (matchesError || rostersError) return <Error />;

	const match = matches[0];
	const opponentRoster = rosters.find((ros) => ros.owner === match.opponent);

	const handleSubmit = async (formData) => {
		const updatedMatch = {
			...match,
			playerData: {
				...match.playerData,
				fansInAttendance:
					parseInt(formData.myFans) + parseInt(formData.oppoFans),
				score: roster.players.reduce((total, player) => {
					if (player.hasOwnProperty('newStats'))
						return total + player.newStats.TD;
					return total;
				}, 0),
			},
			winner: formData.winner,
		};

		const results = await submitMatchSlip(roster, updatedMatch);

		setMatchResults(results);
		showMatchSummary();
	};

	const startOver = () => {
		// eslint-disable-next-line no-restricted-globals
		const c = confirm(
			'Are you sure you want to start this slip over? All progress will be lost.'
		);
		if (c) window.location.reload();
	};

	const handleBlockedNavigation = (nextLocation) => {
		// eslint-disable-next-line no-restricted-globals
		const c = confirm(
			'Are you sure you want to leave this page? All progress will be lost.'
		);
		if (c) {
			setBlockedNavigation(false);
			return true;
		}
		return false;
	};

	return (
		<Container maxWidth="md">
			<Prompt when={blockedNavigation} message={handleBlockedNavigation} />
			<Stepper activeStep={activeStep} sx={{ mb: 2 }}>
				{steps.map((label) => (
					<Step key={label}>
						<StepLabel>{label}</StepLabel>
					</Step>
				))}
			</Stepper>

			{activeStep === 0 && (
				<Pregame
					roster={roster}
					opponentRoster={opponentRoster}
					updateRoster={handleUpdateRoster}
				/>
			)}
			{activeStep === 1 && (
				<PlayerStats roster={roster} updateRoster={handleUpdateRoster} />
			)}
			{activeStep === 2 && (
				<Postgame match={match} handleSubmit={handleSubmit} />
			)}
			<Button
				variant="contained"
				color="secondary"
				fullWidth
				sx={{ mt: 1, mb: 1 }}
				onClick={startOver}
			>
				Start Over
			</Button>

			{matchSummary && (
				<MatchSummaryDialog
					defaultOpen={matchSummary}
					results={matchResults}
					handleCloseDialog={hideMatchSummary}
				/>
			)}
		</Container>
	);
};

export default MatchDetail;
