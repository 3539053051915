import React from 'react';
import { Grid, Button } from '@mui/material';
import FormHarness from '../../FormControls/Harness';
import TextField from '../../FormControls/TextInput';
import SelectField from '../../FormControls/Select';

const Postgame = ({ match, handleSubmit }) => {
	return (
		<FormHarness handleFormSubmit={handleSubmit}>
			<Grid container spacing={1}>
				<Grid item xs={12} md={6}>
					<TextField
						fieldName="myFans"
						label="My Fan Factor"
						fullWidth
						type="number"
						rules={{
							min: {
								value: 2,
								message: 'Your Fan Factor cannot be lower than 2.',
							},
							max: {
								value: 10,
								message: 'Your Fan Factor cannot be higher than 10.',
							},
						}}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<TextField
						fieldName="oppoFans"
						label="Opponent's Fan Factor"
						fullWidth
						type="number"
						rules={{
							min: {
								value: 2,
								message: 'Your Fan Factor cannot be lower than 2.',
							},
							max: {
								value: 10,
								message: 'Your Fan Factor cannot be higher than 10.',
							},
						}}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<SelectField
						fieldName="winner"
						label="Match Result"
						options={[
							{ label: 'Win', value: match.playerData.user },
							{ label: 'Loss', value: match.opponent },
							{ label: 'Draw', value: 'draw' },
						]}
						fullWidth
						labelType="standalone"
					/>
				</Grid>
			</Grid>
			<Button
				variant="contained"
				color="primary"
				sx={{ mt: 1, mb: 1 }}
				type="submit"
				fullWidth
			>
				Save
			</Button>
		</FormHarness>
	);
};

export default Postgame;
