import { database } from './fire';
import { createPlayer } from '../types/player';
import expensiveMistakes from './expensiveMistakes';
import updateDedicatedFans from './fanDisposition';
import logEvent from './eventlog';
import { parseCost } from './textParser';
import { MATCH_TYPES } from '../types/match';

const calculateSPP = (newStats, currentSPP) => {
	return Object.entries(newStats).reduce((acc, [key, value]) => {
		if (key === 'CAS') return acc + value * 2;
		if (key === 'DEF') return acc + value;
		if (key === 'INT') return acc + value;
		if (key === 'MVP') return acc + value * 4;
		if (key === 'PAS') return acc + value;
		if (key === 'TD') return acc + value * 3;
		if (key === 'THR') return acc + value;
		return acc;
	}, currentSPP);
};

export const saveRoster = (newRoster) => {
	database
		.collection('rosters3')
		.doc(newRoster.id)
		.set({ ...newRoster }, { merge: true })
		.catch((err) => {
			console.error(err);
		});
};

export const hirePlayers = (roster, toHire) => {
	const parsed = Array.isArray(toHire) ? toHire : [toHire];
	const players = parsed.map((player) =>
		createPlayer(player, roster.id, roster.factionId)
	);
	const totalCost = players.reduce((acc, player) => acc + player.baseCost, 0);
	const newRoster = {
		...roster,
		players: [...roster.players, ...players],
		treasury: roster.treasury - totalCost,
	};

	database
		.collection('rosters3')
		.doc(roster.id)
		.set({ ...newRoster, value: rosterValuation(newRoster) })
		.then(() => {
			logEvent(
				'PLAYER_HIRED',
				`The ${roster.teamName} have added new players to their roster!`
			);
		})
		.catch((err) => {
			console.error(err);
		});
};

export const updatePlayer = (roster, newPlayerData) => {
	const newRoster = {
		...roster,
		players: roster.players.map((player) => {
			if (player.id === newPlayerData.id) return newPlayerData;
			return player;
		}),
	};

	database
		.collection('rosters3')
		.doc(roster.id)
		.set({ ...newRoster, value: rosterValuation(newRoster) })
		.catch((err) => {
			console.error(err);
		});
};

export const firePlayer = (roster, playerId) => {
	// Cannot have fewer than 11 players on the roster
	if (roster.players.length <= 11) {
		alert('You cannot have fewer than 11 players on your roster.');
		return;
	}

	// eslint-disable-next-line no-restricted-globals
	const c = confirm('Are you sure you want to fire this player?');

	if (c) {
		const updatedRoster = {
			...roster,
			players: roster.players.filter((player) => playerId !== player.id),
		};
		const playerName =
			roster.players.find((player) => playerId === player.id)?.name ||
			'a player';
		database
			.collection('rosters3')
			.doc(roster.id)
			.set({ ...updatedRoster, value: rosterValuation(updatedRoster) })
			.then(() => {
				logEvent(
					'PLAYER_FIRED',
					`The ${roster.teamName} have fired ${playerName}.`
				);
			})
			.catch((err) => {
				console.error(err);
			});
	}
};

export const hireStaff = (roster) => {
	database
		.collection('rosters3')
		.doc(roster.id)
		.set({ ...roster, value: rosterValuation(roster) })
		.then(() => {
			logEvent(
				'STAFF_HIRED',
				`The ${roster.teamName} have hired new support staff.`
			);
		})
		.catch((err) => {
			console.error(err);
		});
};

export const rosterValuation = (roster) => {
	const lowCostLinemen = ['Snotling Lineman', 'Gnoblar Linemen'];

	// Players
	const playerValue = roster.players
		.filter((player) => !player.MNG)
		.filter((player) => !lowCostLinemen.includes(player.position))
		.reduce((total, player) => total + player.value, 0);

	// Staff & Re-Rolls
	const miscItemValue = roster.items
		// Fix a bug where re-rolls are valued too highly
		.map((item) => {
			if (!item.label.localeCompare('rerolls') && item.value >= 100000) {
				return {
					...item,
					value: item.value / 2,
				};
			}
			return item;
		})
		// Do not count extra fans toward TV
		.filter((item) => item.label.localeCompare('Extra Fans'))
		.reduce((total, item) => total + item.qty * item.value, 0);

	return playerValue + miscItemValue;
};

export const submitMatchSlip = (roster, match) => {
	let treasury = roster.treasury;
	const disposition =
		match.winner === roster.owner ? 'W' : match.winner === 'draw' ? 'D' : 'L';
	const isLeagueGame = !match.type.localeCompare(MATCH_TYPES.LEAGUE);

	// Expensive Mistakes
	// Note that we do expensive mistakes out-of-order since we allow players to
	// purchase/upgrade at any time
	const expensiveMistakesDisposition = expensiveMistakes(treasury);
	treasury = expensiveMistakesDisposition.newTreasury;

	// Record Outcome
	let record = roster.record;
	let friendlyRecord = roster.friendlyRecord;
	if (!match.type.localeCompare(MATCH_TYPES.LEAGUE)) {
		record = {
			win: !disposition.localeCompare('W')
				? roster.record.win + 1
				: roster.record.win,
			loss: !disposition.localeCompare('L')
				? roster.record.loss + 1
				: roster.record.loss,
			draw: !disposition.localeCompare('D')
				? roster.record.draw + 1
				: roster.record.draw,
		};
	}
	if (!match.type.localeCompare(MATCH_TYPES.FRIENDLY)) {
		friendlyRecord = {
			win: !disposition.localeCompare('W')
				? roster.friendlyRecord.win + 1
				: roster.friendlyRecord.win,
			loss: !disposition.localeCompare('L')
				? roster.friendlyRecord.loss + 1
				: roster.friendlyRecord.loss,
			draw: !disposition.localeCompare('D')
				? roster.friendlyRecord.draw + 1
				: roster.friendlyRecord.draw,
		};
	}

	// Calculate new league point total
	let leaguePoints = roster.leaguePoints;
	if (!match.type.localeCompare(MATCH_TYPES.LEAGUE)) {
		if (!disposition.localeCompare('W')) leaguePoints += 3;
		if (!disposition.localeCompare('L')) leaguePoints += 0;
		if (!disposition.localeCompare('D')) leaguePoints += 1;
	}

	// Record Winnings
	const totalFans = match.playerData.fansInAttendance;
	const winningsMod =
		parseInt(totalFans) / 2 + parseInt(match.playerData.score);
	const winnings = winningsMod * 10000;
	treasury = parseInt(roster.treasury) + winnings;

	// Update Dedicated Fans
	const dedicatedFans = updateDedicatedFans(disposition, roster.dedicatedFans);

	// Update Players
	let players = roster.players
		.filter((player) => {
			if (player.hasOwnProperty('newStats')) return !player.newStats.DEAD;
			return player;
		})
		.map((player) => {
			if (player.MNG && isLeagueGame) {
				return { ...player, MNG: false };
			}
			if (player.hasOwnProperty('newStats')) {
				let updatedPlayer = {
					...player,
					MNG: player.newStats.MNG,
					nigglingInjuries:
						player.newStats.nigglingInjuries || player.nigglingInjuries,
					stats: {
						CAS: player.newStats.CAS + player.stats.CAS,
						DEF: player.newStats.DEF + player.stats.DEF,
						INT: player.newStats.INT + player.stats.INT,
						MVP: player.newStats.MVP + player.stats.MVP,
						PAS: player.newStats.PAS + player.stats.PAS,
						TD: player.newStats.TD + player.stats.TD,
						THR: player.newStats.THR + player.stats.THR,
					},
					SPP: calculateSPP(player.newStats, player.SPP),
				};
				if (player.newStats.lastingInjury) {
					updatedPlayer[player.newStats.lastingInjury] =
						updatedPlayer[player.newStats.lastingInjury] - 1;
					updatedPlayer.injuryHistory[player.newStats.lastingInjury] =
						updatedPlayer.injuryHistory[player.newStats.lastingInjury] + 1;
				}
				delete updatedPlayer.newStats;
				return updatedPlayer;
			}
			return player;
		});

	// Handle Journeymen
	players = players.filter((player) => {
		if (
			player.hasOwnProperty('isJourneyman') &&
			player.isJourneyman &&
			treasury >= player.baseCost
		) {
			// eslint-disable-next-line no-restricted-globals
			let keepThisPlayer = confirm(
				`Would you like to hire the player ${
					player.name
				}? This player's stats are: ${JSON.stringify(
					player.stats,
					null,
					2
				)} and the cost to hire is: ${player.baseCost}?`
			);
			if (keepThisPlayer) treasury = treasury - player.baseCost;
			return keepThisPlayer;
		}
		return true;
	});

	// Misc. Book-Keeping
	const now = new Date();

	// Assemble new roster
	const newRoster = {
		...roster,
		record,
		friendlyRecord,
		leaguePoints,
		players,
		dedicatedFans,
		treasury,
		lastUpdatedAt: now.getTime(),
	};

	// Push new roster to database
	database
		.collection('rosters3')
		.doc(roster.id)
		.set(
			{
				...newRoster,
				value: rosterValuation(newRoster),
			},
			{ merge: true }
		)
		.catch((err) => {
			console.error(err);
		});

	// Push match record to database
	database
		.collection('matches')
		.doc(match.id)
		.set({
			...match,
			expensiveMistakesDisposition,
			fanDisposition: dedicatedFans - roster.dedicatedFans,
			status: 'Confirmed',
		})
		.then(() => {
			const message = !disposition.localeCompare('W')
				? `The ${match.playerData.roster.teamName} have won their match with a score of ${match.playerData.score}.`
				: !disposition.localeCompare('L')
				? `The ${match.playerData.roster.teamName} have lost their match with a score of ${match.playerData.score}.`
				: `The ${match.playerData.roster.teamName} have tied their match with a score of ${match.playerData.score}.`;
			logEvent('MATCH_END', message);

			if (dedicatedFans - roster.dedicatedFans > 0) {
				logEvent(
					'FAN_GAINED',
					`The ${match.playerData.roster.teamName} have gained a dedicated fan.`
				);
			}
			if (dedicatedFans - roster.dedicatedFans < 0) {
				logEvent(
					'FAN_GAINED',
					`The ${match.playerData.roster.teamName} have lost a dedicated fan.`
				);
			}

			if (expensiveMistakesDisposition.incidentLabel === 'Crisis Averted') {
				logEvent(
					'EXPENSIVE_MISTAKES_CRISIS_AVERTED',
					`The ${match.playerData.roster.teamName} have avoided a big loss after some players tried to loot their treasury.`
				);
			}
			if (expensiveMistakesDisposition.incidentLabel === 'Minor Incident') {
				logEvent(
					'EXPENSIVE_MISTAKES_MINOR_INCIDENT',
					`The ${match.playerData.roster.teamName} have lost ${parseCost(
						expensiveMistakesDisposition.newTreasury -
							expensiveMistakesDisposition.oldTreasury
					)} after some players looted their treasure room.`
				);
			}
			if (expensiveMistakesDisposition.incidentLabel === 'Major Incident') {
				logEvent(
					'EXPENSIVE_MISTAKES_MAJOR_INCIDENT',
					`The ${match.playerData.roster.teamName} have lost ${parseCost(
						expensiveMistakesDisposition.newTreasury -
							expensiveMistakesDisposition.oldTreasury
					)} after some players looted their treasure room.`
				);
			}
			if (expensiveMistakesDisposition.incidentLabel === 'Catastrophe') {
				logEvent(
					'EXPENSIVE_MISTAKES_CATASTROPHE',
					`The ${match.playerData.roster.teamName} have lost ${parseCost(
						expensiveMistakesDisposition.newTreasury -
							expensiveMistakesDisposition.oldTreasury
					)} after some players looted their treasure room.`
				);
			}
		})
		.catch((err) => {
			console.error(err);
		});

	return {
		oldTreasury: roster.treasury,
		newTreasury: treasury,
		winnings,
		expensiveMistakesDisposition,
		oldDedicatedFans: roster.dedicatedFans,
		newDedicatedFans: dedicatedFans,
		disposition,
	};
};

export const killPlayer = (roster, deadPlayer) => {
	const newRoster = {
		...roster,
		players: roster.players.filter((player) => player.id !== deadPlayer.id),
	};

	database
		.collection('rosters3')
		.doc(roster.id)
		.set({ ...newRoster, value: rosterValuation(newRoster) })
		.then(() => {
			logEvent(
				'PLAYER_DIED',
				`${deadPlayer.name || 'Dummy McNoname'} of the ${
					roster.teamName
				} has died!`
			);
		})
		.catch((err) => {
			console.error(err);
		});

	database
		.collection('graveyard')
		.doc(deadPlayer.id)
		.set({ ...deadPlayer, deceased: new Date().getTime() })
		.catch((err) => {
			console.error(err);
		});
};
