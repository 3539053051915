import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Typography, Avatar, Grid } from '@mui/material';
import {
	truncate,
	stringAvatar,
	parseCost,
} from '../../../services/textParser';
import RosterType from '../../../types/roster';
import UserType from '../../../types/user';

const RosterCardMini = ({ roster, owner, handleClick }) => {
	return (
		<Card sx={{ overflow: 'visible', marginBottom: 2 }} onClick={handleClick}>
			<CardContent>
				<Grid container>
					<Avatar
						{...stringAvatar(`${owner.fname} ${owner.lname}`, {
							position: 'absolute',
							transform: 'translate(-50%, -50%)',
						})}
					/>
					<Grid item xs={9}>
						<Typography
							sx={{ paddingLeft: 3, fontWeight: 'bold', ...truncate.sx }}
						>
							{roster.teamName}
						</Typography>
					</Grid>
					<Grid item xs={3}></Grid>
					<Grid item xs={12}>
						<Typography {...truncate}>{roster.factionName} Team</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography {...truncate}>
							({roster.record.win}/{roster.record.loss}/{roster.record.draw})
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography {...truncate}>{parseCost(roster.value)}</Typography>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

RosterCardMini.defaultProps = {
	handleClick: () => null,
};
RosterCardMini.propTypes = {
	roster: RosterType,
	owner: UserType,
	handleClick: PropTypes.func,
};

export default RosterCardMini;
