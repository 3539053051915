import React from "react";
import PropTypes from "prop-types";
import { Stack } from "@mui/material";
import TeamActions from "./TeamActions";
import PlayerList from "./PlayerList";
import AddPlayer from "./AddPlayer";
import EditPlayer from "./EditPlayer";
import EditStaff from "./EditStaff";
import RosterType from "../../types/roster";
import MatchType from "../../types/match";

const TeamManagement = ({ roster, matches }) => {
  const healthyPlayers = roster.players.filter((player) => !player.MNG);
  const injuredPlayers = roster.players.filter((player) => player.MNG);

  const initialState = {
    addPlayer: false,
    editPlayer: false,
    editStaff: false,
  };
  const [drawerState, setDrawerState] = React.useState(initialState);
  const toggleAddPlayerDrawer = () => {
    setDrawerState((prevState) => ({
      ...initialState,
      addPlayer: !prevState.addPlayer,
    }));
  };
  const toggleEditPlayerDrawer = () => {
    setDrawerState((prevState) => ({
      ...initialState,
      editPlayer: !prevState.editPlayer,
    }));
  };
  const toggleEditStaffDrawer = () => {
    setDrawerState((prevState) => ({
      ...initialState,
      editStaff: !prevState.editStaff,
    }));
  };

  const [selectedPlayer, setSelectedPlayer] = React.useState();
  const handleSetSelectedPlayer = (player) => {
    setSelectedPlayer(player);
    toggleEditPlayerDrawer();
  };

  return (
    <Stack direction="column" sx={{ paddingBottom: 2 }}>
      <TeamActions
        roster={roster}
        addPlayer={toggleAddPlayerDrawer}
        editStaff={toggleEditStaffDrawer}
      />
      <PlayerList
        players={healthyPlayers}
        selectPlayer={handleSetSelectedPlayer}
      />
      <PlayerList
        players={injuredPlayers}
        selectPlayer={handleSetSelectedPlayer}
        label="Injured Players"
      />

      {/* Drawers */}
      <AddPlayer
        open={drawerState.addPlayer}
        toggle={toggleAddPlayerDrawer}
        roster={roster}
        treasury={roster.treasury}
        availablePlayers={[]}
      />

      <EditPlayer
        open={drawerState.editPlayer}
        toggle={toggleEditPlayerDrawer}
        roster={roster}
        player={selectedPlayer}
      />

      <EditStaff
        open={drawerState.editStaff}
        toggle={toggleEditStaffDrawer}
        roster={roster}
      />
    </Stack>
  );
};

TeamManagement.propTypes = {
  roster: RosterType.isRequired,
  matches: PropTypes.arrayOf(MatchType).isRequired,
};

export default TeamManagement;
