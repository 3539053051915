import PropTypes from 'prop-types';
import UserType from './user';
import PlayerType from './player';
import RosterType from './roster';
import uid from '../services/uid';
import { database } from '../services/fire';

export const MATCH_TYPES = {
	LEAGUE: 'League Fixture',
	FRIENDLY: 'Cross-Division Friendly',
};

export const MATCH_STATUS = {
	DRAFT: 'Draft',
	SUBMITTED: 'Submitted',
	CONFIRMED: 'Confirmed',
};

const StatType = PropTypes.shape({
	stat: PropTypes.oneOf(['CAS', 'DEF', 'INT', 'MVP', 'PAS', 'THR', 'TD'])
		.isRequired,
	sppAwarded: PropTypes.number.isRequired,
	player: PropTypes.string.isRequired,
});

const match = PropTypes.shape({
	id: PropTypes.string.isRequired,
	playerData: PropTypes.shape({
		user: UserType,
		score: PropTypes.number.isRequired,
		playersInjured: PropTypes.arrayOf(PlayerType),
		fansInAttendance: PropTypes.number.isRequired,
		roster: RosterType.isRequired,
		statsAdded: PropTypes.arrayOf(StatType),
	}),
	opponent: PropTypes.string.isRequired,
	type: PropTypes.oneOf([MATCH_TYPES.LEAGUE, MATCH_TYPES.FRIENDLY]),
	winner: PropTypes.oneOf(['playerA', 'playerB', 'tie']),
	status: PropTypes.oneOf([
		MATCH_STATUS.DRAFT,
		MATCH_STATUS.SUBMITTED,
		MATCH_STATUS.CONFIRMED,
	]),
	matchStartDate: PropTypes.number.isRequired,
});

export const createNewMatch = (roster, userId, opponentId, type) => {
	const matchStartDate = new Date().getTime();
	const newMatch = {
		id: uid(),
		playerData: {
			user: userId,
			score: 0,
			playersInjured: [],
			fansInAttendance: 0,
			roster,
			statsAdded: [],
		},
		opponent: opponentId,
		type,
		winner: 'tie',
		status: MATCH_STATUS.DRAFT,
		matchStartDate,
	};
	return newMatch;
};

export const startMatch = (newMatch) => {
	database
		.collection('matches')
		.doc(newMatch.id)
		.set(newMatch)
		.catch((err) => {
			console.error(err);
		});
};

export default match;
