import PropTypes, { shape } from 'prop-types';

const advancement = shape({
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	cost: PropTypes.number.isRequired,
	level: PropTypes.number.isRequired,
	sppCost: PropTypes.number.isRequired,
});

export default advancement;
