import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Redirect, Route } from 'react-router-dom';
import { auth } from '../../services/fire';
import Loader from '../Loader';
import Error from '../Error';

export default function PrivateRoute({ component: Component, ...rest }) {
	const [user, loading, error] = useAuthState(auth);

	return (
		<Route
			{...rest}
			render={(props) => {
				if (loading) return <Loader />;
				if (error) return <Error message={error.message} dismissable />;
				if (user) return <Component {...props} />;
				return <Redirect to="/login" />;
			}}
		/>
	);
}
