import React from "react";
import { Stack, Typography, Button } from "@mui/material";
import FormHarness from "../../FormControls/Harness";
import SelectField from "../../FormControls/Select";
import { MATCH_TYPES } from "../../../types/match";

const NewMatch = ({
  users,
  handleMatchStart,
  myMatches,
  myCompleteMatches,
  ownerId,
}) => {
  const [opponent, setOpponent] = React.useState();
  const [matchType, setMatchType] = React.useState();

  const handleSubmit = () => {
    handleMatchStart(opponent, MATCH_TYPES[matchType]);
  };

  const availableOpponents = users
    .filter((user) => user.id !== ownerId)
    .map((user) => ({
      label: `${user.fname} ${user.lname}`,
      value: user.id,
    }));
  const availableFriendlyOpponents = users
    .filter((user) => user.id !== ownerId)
    .filter((user) => {
      const alreadyPlayed = myCompleteMatches.find(
        (match) =>
          match.opponent === user.id && match.type === MATCH_TYPES.FRIENDLY
      );
      return !alreadyPlayed;
    })
    .map((user) => ({
      label: `${user.fname} ${user.lname}`,
      value: user.id,
    }));

  const availableLeagueOpponents = users
    .filter((user) => user.id !== ownerId)
    .filter((user) => {
      const alreadyPlayed = myCompleteMatches.find(
        (match) =>
          match.opponent === user.id && match.type === MATCH_TYPES.LEAGUE
      );
      return !alreadyPlayed;
    })
    .map((user) => ({
      label: `${user.fname} ${user.lname}`,
      value: user.id,
    }));

  const existingMatchTypes = myMatches.map((match) => match.type);

  return (
    <FormHarness handleFormSubmit={handleSubmit}>
      <Stack direction="column">
        <Typography variant="h6" sx={{ mb: 1 }}>
          Start a New Match
        </Typography>
        <SelectField
          fieldName="matchType"
          label="Match Type"
          options={Object.keys(MATCH_TYPES)
            .filter((key) => !existingMatchTypes.includes(MATCH_TYPES[key]))
            .map((key) => ({
              label: MATCH_TYPES[key],
              value: key,
            }))}
          fullWidth
          labelType="standalone"
          value={matchType}
          onChange={(e) => setMatchType(e.target.value)}
        />
        <SelectField
          fieldName="opponent"
          label="Select Opponent"
          options={
            matchType === MATCH_TYPES.FRIENDLY
              ? availableFriendlyOpponents
              : matchType === MATCH_TYPES.LEAGUE
              ? availableLeagueOpponents
              : availableOpponents
          }
          fullWidth
          labelType="standalone"
          value={opponent}
          onChange={(e) => setOpponent(e.target.value)}
        />
        <Button variant="contained" color="primary" type="submit">
          Begin Match
        </Button>
      </Stack>
    </FormHarness>
  );
};

export default NewMatch;
