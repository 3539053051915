import React from "react";
import PropTypes from "prop-types";
import { Paper, Stack, Typography, IconButton, Divider } from "@mui/material";
import SportsFootballIcon from "@mui/icons-material/SportsFootball";
import CasinoIcon from "@mui/icons-material/Casino";
import SportsIcon from "@mui/icons-material/Sports";
import PeopleIcon from "@mui/icons-material/People";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import AddBoxIcon from "@mui/icons-material/AddBox";
import RosterType from "../../../types/roster";
import { parseCost } from "../../../services/textParser";
import { useUser } from "../../../contexts/UserContext";

const TeamActions = ({ roster, addPlayer, editStaff }) => {
  const { owner } = useUser();
  const isOwner = owner.uid === roster.owner;
  const staffIcons = {
    rerolls: <CasinoIcon />,
    "Assistant Coaches": <SportsIcon />,
    "Extra Fans": <PeopleIcon />,
    Apothecary: <MedicalServicesIcon />,
    Cheerleaders: <AccessibilityNewIcon />,
  };
  const staff = roster?.items?.map((item) => ({
    label: `${item?.qty}x ${item?.label}`,
    icon: staffIcons[item.label] || <SportsFootballIcon />,
  }));

  return (
    <Paper sx={{ padding: 1, mb: 2 }}>
      <Typography variant="h6" sx={{ mb: 1 }}>
        {roster?.teamName}
      </Typography>
      <Typography variant="body2" sx={{ mb: 1 }}>
        <strong>League Record:</strong> ({roster.record.win}/
        {roster.record.loss}/{roster.record.draw})
      </Typography>
      <Typography variant="body2" sx={{ mb: 1 }}>
        <strong>Friendly Record:</strong> ({roster.friendlyRecord.win}/
        {roster.friendlyRecord.loss}/{roster.friendlyRecord.draw})
      </Typography>
      <Typography variant="body2" sx={{ mb: 1 }}>
        <strong>League Points:</strong> {roster.leaguePoints}
      </Typography>
      <Typography variant="body2" sx={{ mb: 1 }}>
        <strong>Dedicated Fans:</strong> {roster?.dedicatedFans}
      </Typography>
      <Typography variant="body2" sx={{ mb: 1 }}>
        <strong>Treasury</strong>: {parseCost(roster.treasury)}
      </Typography>
      <Typography variant="body2" sx={{ mb: 1 }}>
        <strong>Team Value:</strong> {parseCost(roster.value)}
      </Typography>
      <Divider />
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent={isOwner ? "space-around" : "space-between"}
        mt={2}
        flexWrap="wrap"
      >
        {isOwner && (
          <>
            <IconButton onClick={addPlayer}>
              <Stack alignItems="center">
                <SportsFootballIcon />
                <Typography variant="body2">Add Players</Typography>
              </Stack>
            </IconButton>
            <IconButton onClick={editStaff}>
              <Stack alignItems="center">
                <AddBoxIcon />
                <Typography variant="body2">Add Staff</Typography>
              </Stack>
            </IconButton>
          </>
        )}
        {!isOwner &&
          staff.map((staff) => (
            <Stack
              direction="column"
              alignItems="center"
              sx={{
                flex: { xs: "1 0 100%", sm: "1 1 auto" },
                marginLeft: `0px !important`,
                mb: { xs: `24px !important`, sm: 0 },
              }}
            >
              {staff.icon}
              <Typography variant="body2">{staff.label}</Typography>
            </Stack>
          ))}
      </Stack>
    </Paper>
  );
};

TeamActions.propTypes = {
  roster: RosterType,
  addPlayer: PropTypes.func.isRequired,
  editStaff: PropTypes.func.isRequired,
};

export default TeamActions;
