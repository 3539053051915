import React from 'react';
import {
	Container,
	Button,
	Typography,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PlayerList from '../../TeamManagement/PlayerList';
import BasicDrawer from '../../BasicDrawer';
import LASTING_INJURIES from '../../../constants/lastingInjuries.json';

const DrawerContent = styled(Container)(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	display: 'flex',
	flexDirection: 'column',
	flex: '1 1 auto',
	paddingTop: theme.spacing(1),
}));

const newStatsModel = {
	CAS: 0,
	DEF: 0,
	INT: 0,
	MVP: 0,
	PAS: 0,
	TD: 0,
	THR: 0,
	MNG: false,
	DEAD: false,
	nigglingInjuries: 0,
	lastingInjury: undefined,
};

const PlayerStats = ({ roster, updateRoster }) => {
	const [players, setPlayers] = React.useState(roster.players);
	const [activePlayer, setActivePlayer] = React.useState();
	const [newStats, setNewStats] = React.useState(newStatsModel);

	const handleSelectPlayer = (player) => {
		setActivePlayer(player);
	};

	const handleUpdatePlayer = () => {
		setPlayers((prevState) =>
			prevState.map((player) => {
				if (player.id === activePlayer.id) return { ...player, newStats };
				return player;
			})
		);
		setNewStats(newStatsModel);
		setActivePlayer(undefined);
	};

	const handleUpdateRoster = () => {
		updateRoster({ ...roster, players });
	};

	return (
		<>
			<PlayerList players={players} selectPlayer={handleSelectPlayer} />
			<Button
				variant="contained"
				color="primary"
				fullWidth
				sx={{ mb: 2 }}
				onClick={handleUpdateRoster}
			>
				Next
			</Button>
			<BasicDrawer
				anchor="bottom"
				open={!!activePlayer}
				onClose={() => setActivePlayer(undefined)}
			>
				{!!activePlayer && (
					<DrawerContent>
						<Typography variant="h6">
							#{activePlayer?.number || '00'} -{' '}
							{activePlayer?.name || 'Dummy McNoname'}
						</Typography>
						<Typography variant="caption" sx={{ mb: 2, display: 'block' }}>
							{activePlayer.position}
						</Typography>
						<FormControl fullWidth>
							<InputLabel id="mng">Player to Miss Next Game</InputLabel>
							<Select
								labelId="mng"
								fieldName="MNG"
								label="Lasting Injury"
								defaultValue={activePlayer?.newStats?.MNG || false}
								sx={{ backgroundColor: 'white', mb: 2 }}
								onChange={(e) =>
									setNewStats({ ...newStats, MNG: e.target.value })
								}
							>
								<MenuItem value={false}>No</MenuItem>
								<MenuItem value={true}>Yes</MenuItem>
							</Select>
						</FormControl>
						<FormControl fullWidth>
							<InputLabel id="lastingInjury">Lasting Injury</InputLabel>
							<Select
								labelId="lastingInjury"
								fieldName="lastingInjury"
								label="Lasting Injury"
								sx={{ backgroundColor: 'white', mb: 2 }}
								defaultValue={activePlayer?.newStats?.lastingInjury || false}
								onChange={(e) =>
									setNewStats({ ...newStats, lastingInjury: e.target.value })
								}
							>
								<MenuItem value={false}>None</MenuItem>
								{LASTING_INJURIES.map(({ label, characteristic }) => (
									<MenuItem value={characteristic}>{label}</MenuItem>
								))}
							</Select>
						</FormControl>
						<TextField
							fieldName="nigglingInjuries"
							label="Niggling Injuries"
							fullWidth
							defaultValue={activePlayer?.newStats?.nigglingInjuries || 0}
							type="number"
							sx={{ backgroundColor: 'white', mb: 2 }}
							onChange={(e) =>
								setNewStats({
									...newStats,
									nigglingInjuries: parseInt(e.target.value),
								})
							}
						/>
						<FormControl fullWidth>
							<InputLabel id="DEAD">Player Died</InputLabel>
							<Select
								labelId="DEAD"
								fieldName="DEAD"
								label="Player Died"
								defaultValue={activePlayer?.newStats?.DEAD || false}
								sx={{ backgroundColor: 'white', mb: 2 }}
								onChange={(e) =>
									setNewStats({ ...newStats, DEAD: e.target.value })
								}
							>
								<MenuItem value={false}>No</MenuItem>
								<MenuItem value={true}>Yes</MenuItem>
							</Select>
						</FormControl>
						<TextField
							fieldName="CAS"
							label="Casualties"
							fullWidth
							defaultValue={activePlayer?.newStats?.CAS || 0}
							type="number"
							sx={{ backgroundColor: 'white', mb: 2 }}
							onChange={(e) =>
								setNewStats({ ...newStats, CAS: parseInt(e.target.value) })
							}
						/>
						<TextField
							fieldName="DEF"
							label="Deflections"
							fullWidth
							defaultValue={activePlayer?.newStats?.DEF || 0}
							type="number"
							sx={{ backgroundColor: 'white', mb: 2 }}
							onChange={(e) =>
								setNewStats({ ...newStats, DEF: parseInt(e.target.value) })
							}
						/>
						<TextField
							fieldName="INT"
							label="Interceptions"
							fullWidth
							defaultValue={activePlayer?.newStats?.INT || 0}
							type="number"
							sx={{ backgroundColor: 'white', mb: 2 }}
							onChange={(e) =>
								setNewStats({ ...newStats, INT: parseInt(e.target.value) })
							}
						/>
						<TextField
							fieldName="MVP"
							label="MVP"
							fullWidth
							defaultValue={activePlayer?.newStats?.MVP || 0}
							type="number"
							sx={{ backgroundColor: 'white', mb: 2 }}
							onChange={(e) =>
								setNewStats({ ...newStats, MVP: parseInt(e.target.value) })
							}
						/>
						<TextField
							fieldName="PAS"
							label="Completed Passes"
							fullWidth
							defaultValue={activePlayer?.newStats?.PAS || 0}
							type="number"
							sx={{ backgroundColor: 'white', mb: 2 }}
							onChange={(e) =>
								setNewStats({ ...newStats, PAS: parseInt(e.target.value) })
							}
						/>
						<TextField
							fieldName="TD"
							label="Touchdowns"
							fullWidth
							defaultValue={activePlayer?.newStats?.TD || 0}
							type="number"
							sx={{ backgroundColor: 'white', mb: 2 }}
							onChange={(e) =>
								setNewStats({ ...newStats, TD: parseInt(e.target.value) })
							}
						/>
						<TextField
							fieldName="THR"
							label="Successful Throws"
							fullWidth
							defaultValue={activePlayer?.newStats?.THR || 0}
							type="number"
							sx={{ backgroundColor: 'white', mb: 2 }}
							onChange={(e) =>
								setNewStats({ ...newStats, THR: parseInt(e.target.value) })
							}
						/>
						<Button
							variant="contained"
							color="primary"
							sx={{ mb: 2 }}
							type="submit"
							fullWidth
							onClick={handleUpdatePlayer}
						>
							Save
						</Button>
						<Button
							variant="contained"
							color="secondary"
							sx={{ mb: 2 }}
							type="button"
							fullWidth
							onClick={() => setActivePlayer(undefined)}
						>
							Cancel
						</Button>
					</DrawerContent>
				)}
			</BasicDrawer>
		</>
	);
};

export default PlayerStats;
