import React from "react";
import PropTypes from "prop-types";
import { Container, Grid, Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useRoster } from "../../../contexts/RosterContext";
import BasicDrawer from "../../BasicDrawer";
import FormHarness from "../../FormControls/Harness";
import TextInput from "../../FormControls/TextInput";
import RosterType from "../../../types/roster";
import { parseCost } from "../../../services/textParser";
import { hireStaff } from "../../../services/rosters";
import { useUser } from "../../../contexts/UserContext";

const DrawerContent = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  flexDirection: "column",
  flex: "1 1 auto",
}));

const EditStaff = ({ roster, open, toggle }) => {
  const { owner } = useUser();
  const isOwner = owner.uid === roster.owner;
  const { staff, teams } = useRoster();
  const team = teams.find((t) => !t.id.localeCompare(roster.factionId));

  const handleUpdateStaff = (formData) => {
    const parsedFormData = Object.entries(formData).map(([key, value]) => {
      const item = roster.items.find((item) => !item.label.localeCompare(key));
      const cost = !item.label.localeCompare("rerolls")
        ? (parseInt(team.rerolls.cost) * 2)
        : staff.find((member) => !member.label.localeCompare(item.label))?.cost;
      return {
        label: item.label,
        newQty: parseInt(value),
        oldQty: parseInt(item?.qty),
        numAdded: parseInt(value) - parseInt(item?.qty),
        cost: parseInt(cost),
        newCost: cost * (parseInt(value) - parseInt(item?.qty)),
      };
    });

    const totalCost = parsedFormData.reduce((acc, cur) => cur.newCost + acc, 0);

    if (totalCost <= roster.treasury) {
      hireStaff({
        ...roster,
        items: parsedFormData.map((item) => ({
          label: item.label,
          qty: item.newQty,
          value: item.cost,
        })),
        treasury: parseInt(roster.treasury - totalCost),
      });
    } else {
      console.log("Not enough money");
    }
  };

  return (
    <BasicDrawer anchor="bottom" open={open} onClose={toggle}>
      <DrawerContent>
        <Typography variant="h5" sx={{ mt: 1 }}>
          Staff &amp; Miscellaneous Items
        </Typography>
        <Typography variant="caption" sx={{ mb: 2 }}>
          Treasury: {parseCost(roster.treasury)}
        </Typography>
        <FormHarness handleFormSubmit={handleUpdateStaff}>
          <Grid container spacing={1}>
            {roster.items.map((item) => {
              const staffMember = staff.find(
                (elem) => !elem.label.localeCompare(item.label)
              );
              const min = item.qty;
              const max = item.label.localeCompare("rerolls")
                ? staffMember?.max
                : 8;
              const cost = item.label.localeCompare("rerolls")
                ? staffMember?.cost
                : parseInt(team.rerolls.cost) * 2;
              return (
                <Grid item xs={12} md={6} key={item.label}>
                  <TextInput
                    fieldName={item.label}
                    label={`${min} - ${max} ${item.label} (${parseCost(cost)})`}
                    fullWidth
                    defaultValue={item.qty}
                    type="number"
                    rules={{ min, max }}
                    disabled={
                      !item.label.localeCompare("Extra Fans") || !isOwner
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            sx={{ mb: 1 }}
            disabled={!isOwner}
          >
            Save Changes
          </Button>
          <Button
            variant="contained"
            color="secondary"
            type="button"
            onClick={toggle}
            fullWidth
          >
            {isOwner ? "Cancel" : "Close"}
          </Button>
        </FormHarness>
      </DrawerContent>
    </BasicDrawer>
  );
};

EditStaff.defaultProps = {
  open: false,
};
EditStaff.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  roster: RosterType,
};

export default EditStaff;
