import { database } from './fire';
import uid from './uid';

const EVENT_TYPES = {
	MATCH_START: 'Play Blood Bowl!',
	MATCH_END: 'Another one in the books!',
	PLAYER_HIRED: 'Fresh Meat!',
	PLAYER_FIRED: "You don't have to go home...",
	PLAYER_DIED: 'Another one bites the dust!',
	STAFF_HIRED: 'Welcome Aboard!',
	FAN_GAINED: 'Jump-On the Bandwagon!',
	FAN_LOST: 'Nothing worse than a fair-weather fan...',
	EXPENSIVE_MISTAKES_CRISIS_AVERTED: 'A near miss',
	EXPENSIVE_MISTAKES_MINOR_INCIDENT: 'A little harmless fun...',
	EXPENSIVE_MISTAKES_MAJOR_INCIDENT: 'Gonna have to do a stock sale...',
	EXPENSIVE_MISTAKES_CATASTROPHE: 'Calamity! Collapse! Destitution!',
	ROSTER_ADDED: 'A Challenger Approaches',
};

const logEvent = (type, message) => {
	const event = {
		id: uid(),
		type: EVENT_TYPES[type] || 'Event',
		message,
		timestamp: new Date().getTime(),
	};

	database
		.collection('log')
		.doc(event.id)
		.set({ ...event }, { merge: true })
		.catch((err) => {
			console.error(err);
		});
};

export default logEvent;
