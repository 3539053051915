import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Container } from "@mui/material";
import { AuthProvider } from "./contexts/AuthContext";
import { UserProvider } from "./contexts/UserContext";
import { RosterProvider } from "./contexts/RosterContext";
import PrivateRoute from "./components/PrivateRoute";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import MyTeam from "./containers/MyTeam";
import TeamCreation from "./containers/TeamCreation";
import TeamDetail from "./containers/TeamDetail";
import MatchManager from "./containers/MatchManager";
import MatchDetail from "./containers/MatchDetail";
import League from "./containers/League";
import Stats from "./containers/Stats";
// import Home from './containers/Home';
import TopBar from "./components/TopBar";

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <Container
          maxWidth="xl"
          sx={{
            backgroundColor: "rgba(245, 241, 238, 1)",
            minHeight: "100vh",
          }}
        >
          <TopBar />
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/signup">
              <Signup />
            </Route>
            <UserProvider>
              <RosterProvider>
                <PrivateRoute path="/" exact>
                  <MyTeam />
                </PrivateRoute>
                <PrivateRoute path="/matchup" exact>
                  <MatchManager />
                </PrivateRoute>
                <PrivateRoute path="/matchup/:matchId">
                  <MatchDetail />
                </PrivateRoute>
                <PrivateRoute path="/team" exact>
                  <MyTeam />
                </PrivateRoute>
                <PrivateRoute path="/create-team">
                  <TeamCreation />
                </PrivateRoute>
                <PrivateRoute path="/team/:rosterId">
                  <TeamDetail />
                </PrivateRoute>
                <PrivateRoute path="/league">
                  <League />
                </PrivateRoute>
                <PrivateRoute path="/stats">
                  <Stats />
                </PrivateRoute>
              </RosterProvider>
            </UserProvider>
          </Switch>
        </Container>
      </AuthProvider>
    </Router>
  );
};

export default App;
