import React from 'react';
import PropTypes from 'prop-types';
import { Container, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import BasicDrawer from '../../BasicDrawer';
import FormHarness from '../../FormControls/Harness';
import RosterType from '../../../types/roster';
import { parseCost } from '../../../services/textParser';
import { hirePlayers } from '../../../services/rosters';
import SelectField from '../../FormControls/Select';

const DrawerContent = styled(Container)(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	display: 'flex',
	flexDirection: 'column',
	flex: '1 1 auto',
}));

const AddPlayer = ({ roster, treasury, open, toggle }) => {
	const handleAddPlayer = (formData) => {
		const player = roster.positions.find(
			(position) => position.id === formData.player
		);
		if (player !== undefined) {
			hirePlayers(roster, player);
			toggle();
		}
	};

	const playerOptions = roster.positions
		.filter((position) => {
			const canAffordPlayer = position.cost <= treasury;
			const spaceOnRoster = roster.players.length < 16;
			const maxNotReached =
				roster.players.reduce((acc, player) => {
					return player.position === position.position ? acc + 1 : acc;
				}, 0) < position.max;

			return canAffordPlayer && spaceOnRoster && maxNotReached;
		})
		.map(({ position, cost, id }) => ({
			label: `${position} (${parseCost(cost)})`,
			value: id,
		}));

	return (
		<BasicDrawer anchor="bottom" open={open} onClose={toggle}>
			<DrawerContent>
				<Typography variant="h5" sx={{ mt: 1 }}>
					Hire a Player
				</Typography>
				<Typography variant="caption" sx={{ mb: 2 }}>
					Treasury: {parseCost(treasury)}
				</Typography>
				{playerOptions.length > 0 && (
					<FormHarness handleFormSubmit={handleAddPlayer}>
						<SelectField
							fieldName="player"
							label="Player Position"
							options={playerOptions}
							fullWidth
							labelType="standalone"
						/>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							fullWidth
							sx={{ mb: 1 }}
						>
							Hire Player
						</Button>
						<Button
							variant="contained"
							color="secondary"
							fullWidth
							onClick={toggle}
						>
							Cancel
						</Button>
					</FormHarness>
				)}
				{!playerOptions.length && (
					<Typography>No players available for hire...</Typography>
				)}
			</DrawerContent>
		</BasicDrawer>
	);
};

AddPlayer.defaultProps = {
	open: false,
};
AddPlayer.propTypes = {
	open: PropTypes.bool,
	toggle: PropTypes.func.isRequired,
	roster: RosterType,
	treasury: PropTypes.number.isRequired,
};

export default AddPlayer;
