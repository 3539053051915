import PropTypes, { shape } from 'prop-types';

const rosterItem = shape({
	label: PropTypes.string.isRequired,
	qty: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
});

export const createRosterItem = (label, qty, value) => ({
	label,
	qty,
	value,
});

export default rosterItem;
