import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { styled } from '@mui/system';
import { Stack, Typography, Divider } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import ROUTE_DATA from '../../constants/routes.json';

const ActiveNavLink = styled(Typography)(({ theme }) => ({
	color: theme.palette.secondary.main,
	cursor: 'pointer',
	position: 'relative',
	'&:before': {
		content: '""',
		position: 'absolute',
		bottom: '-10px',
		width: '4px',
		height: '4px',
		borderRadius: '100%',
		left: 'calc(50% - 2px)',
		backgroundColor: theme.palette.secondary.main,
	},
}));
const InactiveNavLink = styled(Typography)(({ theme }) => ({
	color: theme.palette.primary.main,
	cursor: 'pointer',
}));

const TopBar = () => {
	const { pathname } = useLocation();
	const history = useHistory();
	const handleClick = (to) => history.push(to);
	const { user } = useAuth();

	const NavLink = ({ label, to }) => {
		const { navCategory } = ROUTE_DATA.routes.find(({ path }) => path === to);
		return pathname === navCategory ? (
			<ActiveNavLink onClick={() => handleClick(to)}>{label}</ActiveNavLink>
		) : (
			<InactiveNavLink onClick={() => handleClick(to)}>{label}</InactiveNavLink>
		);
	};

	if (user === null) return null;

	return (
		<>
			<Stack
				direction="row"
				justifyContent="space-around"
				alignItems="center"
				component="nav"
				sx={{ marginBottom: 2, marginTop: 1 }}
			>
				<NavLink label="League" to="/league" />
				<NavLink label="My Team" to="/team" />
				<NavLink label="Matchups" to="/matchup" />
			</Stack>
			<Divider sx={{ mb: 2 }} />
		</>
	);
};

export default TopBar;
