import React from 'react';
import { Box, Typography, Card, CardContent, Button } from '@mui/material';
import { styled } from '@mui/system';
import { Redirect, useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import FormHarness from '../../components/FormControls/Harness';
import TextInput from '../../components/FormControls/TextInput';

const SignupWrapper = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	minHeight: '100vh',
	backgroundColor: theme.palette.background.default,
}));

const SignupCard = styled(Card)(({ theme }) => ({
	minWidth: '33vw',
}));

const Signup = () => {
	const { signup, user } = useAuth();
	const history = useHistory();

	const handleLink = (path) => {
		history.push(path);
	};

	if (user !== null) {
		return <Redirect to="/team" />;
	}

	const handleSignup = (formData) => {
		if (formData.password === formData.confirm) {
			signup(formData);
			handleLink(`/login`);
		} else {
			alert('Your passwords do not match!');
		}
	};

	return (
		<SignupWrapper>
			<SignupCard>
				<CardContent>
					<Typography variant="h4" align="center" mb={2}>
						Sign Up
					</Typography>
					<FormHarness handleFormSubmit={handleSignup}>
						<TextInput fieldName="fname" label="First Name" />
						<TextInput fieldName="lname" label="Last Name" />
						<TextInput fieldName="email" label="Email Address" />
						<TextInput fieldName="password" label="Password" type="password" />
						<TextInput
							fieldName="confirm"
							label="Confirm Password"
							type="password"
						/>
						<Button type="submit" variant="contained" fullWidth>
							Signup
						</Button>
					</FormHarness>
				</CardContent>
			</SignupCard>
		</SignupWrapper>
	);
};

export default Signup;
