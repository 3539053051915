import React from 'react';
import PropTypes from 'prop-types';
import {
	Stack,
	Paper,
	Typography,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Chip,
	Divider,
} from '@mui/material';
import PlayerType from '../../../types/player';
import { parseCost, truncate } from '../../../services/textParser';
import LEVELS from '../../../constants/levels.json';

const levelColors = {
	Novice: 'rgba(0, 0, 0, 0.08)',
	Experienced: '#90ee90',
	Veteran: '#ffa500',
	'Emerging Star': '#FF0000',
	Star: '#cd7f32',
	'Super Star': '#ffd700',
	Legend: '#e5e4e2',
};

const PlayerList = ({ players, label, selectPlayer }) => {
	return (
		<Paper sx={{ mb: 2 }}>
			{!!label && (
				<Typography variant="h6" p={1}>
					{label}
				</Typography>
			)}
			<List>
				{players
					.sort((a, b) => {
						const positionA = a.position.toLowerCase();
						const positionB = b.position.toLowerCase();
						if (positionA < positionB) return -1;
						if (positionA > positionB) return 1;
						return 0;
					})
					.map((player, index, self) => {
						const level = LEVELS.find(
							({ level }) => level === player?.level + 1
						);
						const levelColor = levelColors[level.label];
						return (
							<div key={player.id}>
								<ListItem onClick={() => selectPlayer(player)}>
									<ListItemIcon>
										<Chip
											label={`#${player.number || '00'}`}
											style={{ backgroundColor: levelColor }}
										/>
									</ListItemIcon>
									<ListItemText>
										<Stack
											direction="row"
											justifyContent="space-between"
											alignItems="center"
										>
											<Stack
												direction="column"
												sx={{
													flex: { xs: '1 1 auto', md: '0 0 25%' },
													maxWidth: { xs: '100%', md: '25%' },
												}}
											>
												<Typography {...truncate}>
													{player.name || 'Dummy McNoname'}
												</Typography>
												<Typography variant="caption">
													{level.label} {player.position}
												</Typography>
											</Stack>
											<Typography
												variant="caption"
												sx={{
													flex: '0 0 50%',
													paddingLeft: { xs: 0, md: '10px' },
													display: { xs: 'none', md: 'inherit' },
												}}
											>
												{player.skills.join(', ').replace(/^, /, '')}
											</Typography>
											<Typography
												variant="caption"
												sx={{
													flex: '0 0 12.5%',
													textAlign: 'right',
													display: { xs: 'none', md: 'inherit' },
												}}
											>
												{player.SPP} SPP
											</Typography>
											<Typography
												variant="caption"
												sx={{
													flex: '0 0 12.5%',
													textAlign: 'right',
													display: { xs: 'none', md: 'inherit' },
												}}
											>
												{parseCost(player.value)}
											</Typography>
										</Stack>
									</ListItemText>
								</ListItem>
								{index + 1 !== self.length && <Divider />}
							</div>
						);
					})}
			</List>
			{players.length === 0 && (
				<Typography
					variant="body2"
					sx={{ p: 1, pt: 0, display: 'block', textAlign: 'center' }}
				>
					No Players
				</Typography>
			)}
		</Paper>
	);
};

PlayerList.defaultProps = {
	label: 'Players',
	players: [],
};
PlayerList.propTypes = {
	label: PropTypes.string,
	players: PropTypes.arrayOf(PlayerType),
	selectPlayer: PropTypes.func.isRequired,
};

export default PlayerList;
