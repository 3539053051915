import PropTypes, { shape } from 'prop-types';

const stats = shape({
	CAS: PropTypes.number.isRequired,
	DEF: PropTypes.number.isRequired,
	INT: PropTypes.number.isRequired,
	MVP: PropTypes.number.isRequired,
	PAS: PropTypes.number.isRequired,
	THR: PropTypes.number.isRequired,
	TD: PropTypes.number.isRequired,
});

export const createStats = (startingStats) => ({
	CAS: startingStats?.CAS || 0,
	DEF: startingStats?.DEF || 0,
	INT: startingStats?.INT || 0,
	MVP: startingStats?.MVP || 0,
	PAS: startingStats?.PAS || 0,
	THR: startingStats?.THR || 0,
	TD: startingStats?.TD || 0,
});

export default stats;
