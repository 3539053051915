import { styled } from '@mui/material/styles';
import { Drawer } from '@mui/material';

const BasicDrawer = styled(Drawer)(({ theme }) => ({
	'& .MuiDrawer-paper': {
		minHeight: '90vh',
		maxHeight: '90vh',
		borderTopLeftRadius: '16px',
		borderTopRightRadius: '16px',
		backgroundColor: theme.palette.background.default,
	},
}));

export default BasicDrawer;
