import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import BasicDrawer from "../../BasicDrawer";
import FormHarness from "../../FormControls/Harness";
import TextInput from "../../FormControls/TextInput";
import SelectField from "../../FormControls/Select";
import RosterType from "../../../types/roster";
import PlayerType from "../../../types/player";
import { parseCost } from "../../../services/textParser";
import { updatePlayer, firePlayer } from "../../../services/rosters";
import LEVELS from "../../../constants/levels.json";
import SKILLS from "../../../constants/skills.json";
import COSTS from "../../../constants/costs.json";
import { useUser } from "../../../contexts/UserContext";

const DrawerContent = styled(Container)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  flexDirection: "column",
  flex: "1 1 auto",
  paddingTop: theme.spacing(1),
}));

const PlayerMetadata = ({ player, playerLevel, handleEditPlayer, isOwner }) => (
  <Stack direction="row" justifyContent="space-between">
    <Stack>
      <Typography variant="h5">
        #{player?.number} {player?.name || "Dummy McNoname"}
      </Typography>
      <Typography sx={{ mb: 2 }} variant="caption">
        {playerLevel?.label} {player?.position} | {parseCost(player?.value)}
      </Typography>
    </Stack>
    {isOwner && (
      <Stack>
        <IconButton onClick={handleEditPlayer}>
          <EditIcon />
        </IconButton>
      </Stack>
    )}
  </Stack>
);

const PlayerVitals = ({ player }) => (
  <TableContainer component={Paper} sx={{ mb: 2 }}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell sx={{ fontWeight: "bold" }}>MA</TableCell>
          <TableCell sx={{ fontWeight: "bold" }}>ST</TableCell>
          <TableCell sx={{ fontWeight: "bold" }}>AG</TableCell>
          <TableCell sx={{ fontWeight: "bold" }}>PA</TableCell>
          <TableCell sx={{ fontWeight: "bold" }}>AV</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>{player.MA}</TableCell>
          <TableCell>{player.ST}</TableCell>
          <TableCell>{player.AG}+</TableCell>
          <TableCell>{player.PA}+</TableCell>
          <TableCell>{player.AV}+</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);

const PlayerSkills = ({ player, handleEditPlayer, isOwner }) => (
  <TableContainer component={Paper} sx={{ mb: 2 }}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell sx={{ fontWeight: "bold" }} colSpan={isOwner ? 4 : 5}>
            Skills &amp; Traits
          </TableCell>
          {isOwner && (
            <TableCell align="right">
              <IconButton onClick={handleEditPlayer}>
                <EditIcon />
              </IconButton>
            </TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell colSpan={5}>
            {player.skills.filter(String).length
              ? player.skills
                  .filter((skill) => typeof skill === "string" && skill.length)
                  .join(", ")
              : "N/A"}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);

const PlayerStats = ({ player }) => (
  <TableContainer component={Paper} sx={{ mb: 2 }}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell colSpan={2} sx={{ fontWeight: "bold" }}>
            Statistics
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Star Player Points (SPP)</TableCell>
          <TableCell>{player.SPP}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Casualties (CAS)</TableCell>
          <TableCell>{player.stats.CAS}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Deflections (DEF)</TableCell>
          <TableCell>{player.stats.DEF}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Interceptions (INT)</TableCell>
          <TableCell>{player.stats.INT}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Most Valuable Player (MVP)</TableCell>
          <TableCell>{player.stats.MVP}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Completed Passes (PAS)</TableCell>
          <TableCell>{player.stats.PAS}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Completed Throws (THR)</TableCell>
          <TableCell>{player.stats.THR}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Touchdowns (TD)</TableCell>
          <TableCell>{player.stats.TD}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);

const PlayerInjuryHistory = ({ player }) => (
  <TableContainer component={Paper} sx={{ mb: 2 }}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell colSpan={2} sx={{ fontWeight: "bold" }}>
            Injury History
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Head Injuries (AV)</TableCell>
          <TableCell>{player.injuryHistory.AV}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Smashed Knees (MA)</TableCell>
          <TableCell>{player.injuryHistory.MA}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Broken Arms (PA)</TableCell>
          <TableCell>{player.injuryHistory.PA}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Neck Injuries (AG)</TableCell>
          <TableCell>{player.injuryHistory.AG}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Dislocated Shoulders (ST)</TableCell>
          <TableCell>{player.injuryHistory.ST}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Niggling Injuries (NI)</TableCell>
          <TableCell>{player.nigglingInjuries}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);

const EditPlayerForm = ({ player, handleSubmit, cancel }) => (
  <FormHarness handleFormSubmit={handleSubmit}>
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        <TextInput
          fieldName="name"
          label="Player Name"
          fullWidth
          defaultValue={player.name}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          fieldName="number"
          label="Player Number"
          fullWidth
          defaultValue={player.number}
        />
      </Grid>
    </Grid>
    <Button variant="contained" fullWidth type="submit" sx={{ mb: 1 }}>
      Save Changes
    </Button>
    <Button variant="contained" color="secondary" fullWidth onClick={cancel}>
      Cancel
    </Button>
  </FormHarness>
);

const EditPlayerStats = ({ player, playerLevel, handleSubmit, cancel }) => {
  const parseFormData = (formData) => {
    const newSPP =
      (parseInt(formData.CAS) - parseInt(player.stats.CAS)) * 2 +
      (parseInt(formData.DEF) - parseInt(player.stats.DEF)) * 1 +
      (parseInt(formData.INT) - parseInt(player.stats.INT)) * 1 +
      (parseInt(formData.MVP) - parseInt(player.stats.MVP)) * 4 +
      (parseInt(formData.PAS) - parseInt(player.stats.PAS)) * 1 +
      (parseInt(formData.TD) - parseInt(player.stats.TD)) * 3 +
      (parseInt(formData.THR) - parseInt(player.stats.THR)) * 1;
    handleSubmit({
      SPP: parseInt(player.SPP) + parseInt(newSPP),
      stats: {
        CAS: parseInt(formData.CAS),
        DEF: parseInt(formData.DEF),
        INT: parseInt(formData.INT),
        MVP: parseInt(formData.MVP),
        PAS: parseInt(formData.PAS),
        TD: parseInt(formData.TD),
        THR: parseInt(formData.THR),
      },
    });
  };
  return (
    <FormHarness handleFormSubmit={parseFormData}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5">
            #{player?.number} {player?.name || "Dummy McNoname"}
          </Typography>
          <Typography sx={{ mb: 2 }} variant="caption">
            {playerLevel?.label} {player?.position} | Star Player Points:{" "}
            {player.SPP}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            fieldName="CAS"
            label="Casualties"
            fullWidth
            defaultValue={player.stats.CAS}
            type="number"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            fieldName="DEF"
            label="Deflections"
            fullWidth
            defaultValue={player.stats.DEF}
            type="number"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            fieldName="INT"
            label="Interceptions"
            fullWidth
            defaultValue={player.stats.INT}
            type="number"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            fieldName="MVP"
            label="Most Valuable Players"
            fullWidth
            defaultValue={player.stats.MVP}
            type="number"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            fieldName="PAS"
            label="Completed Passes"
            fullWidth
            defaultValue={player.stats.PAS}
            type="number"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            fieldName="THR"
            label="Successful Throws"
            fullWidth
            defaultValue={player.stats.THR}
            type="number"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            fieldName="TD"
            label="Touchdowns"
            fullWidth
            defaultValue={player.stats.TD}
            type="number"
          />
        </Grid>
      </Grid>
      <Button variant="contained" fullWidth type="submit" sx={{ mb: 1 }}>
        Save Changes
      </Button>
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        type="button"
        onClick={cancel}
      >
        Cancel
      </Button>
    </FormHarness>
  );
};

const EditPlayerSkillsForm = ({
  player,
  playerLevel,
  handleSubmit,
  cancel,
}) => {
  const parseFormData = (formData) => {
    const selections = Object.keys(formData).filter((key) => !!formData[key]);

    if (selections.length > 1) {
      console.log("Too many selections");
    } else if (!!formData["randomCharacteristic"]) {
      const characteristic = formData["randomCharacteristic"];
      const cost = COSTS[characteristic];
      const sppCost = playerLevel["randomCharacteristic"];
      const updatedPlayer = {
        SPP: parseInt(player.SPP) - parseInt(sppCost),
        skills: [...player.skills, `${characteristic}+`],
        advancements: [
          ...player.advancements,
          {
            type: "randomCharacteristic",
            skill: characteristic,
            cost: sppCost,
            currentLevel:
              LEVELS.find(({ level }) => level === player?.level + 1)?.label ||
              "",
            nextLevel:
              LEVELS.find(({ level }) => level === player?.level + 2)?.label ||
              "",
            datePurchased: Date.now(),
          },
        ],
        level: player.level + 1,
        value: player.value + cost,
      };
      updatedPlayer[characteristic] = parseInt(player[characteristic]) + 1;
      handleSubmit(updatedPlayer);
    } else {
      const cost = playerLevel[selections[0]];
      handleSubmit({
        SPP: parseInt(player.SPP) - parseInt(cost),
        skills: [...player.skills, formData[selections[0]]],
        advancements: [
          ...player.advancements,
          {
            type: selections[0],
            skill: formData[selections[0]],
            cost,
            currentLevel:
              LEVELS.find(({ level }) => level === player?.level + 1)?.label ||
              "",
            nextLevel:
              LEVELS.find(({ level }) => level === player?.level + 2)?.label ||
              "",
            datePurchased: Date.now(),
          },
        ],
        level: player.level + 1,
        value: player.value + COSTS[selections[0]],
      });
    }
  };
  return (
    <FormHarness handleFormSubmit={parseFormData}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5">
            #{player?.number} {player?.name || "Dummy McNoname"}
          </Typography>
          <Typography sx={{ mb: 2 }} variant="caption">
            {playerLevel?.label} {player?.position} | Star Player Points:{" "}
            {player.SPP}
          </Typography>
        </Grid>
        {player.SPP >= playerLevel.randomPrimary && (
          <Grid item xs={12} md={6}>
            <SelectField
              fieldName="randomPrimary"
              label="Add a Random Primary Skill"
              options={SKILLS.filter((skill) =>
                player.primary.includes(skill.type)
              )
                .filter((skill) => !player.skills.includes(skill.name))
                .map((skill) => ({
                  label: `${skill.name} (${skill.type})`,
                  value: skill.name,
                }))}
              fullWidth
              labelType="standalone"
              disabled={player.SPP < playerLevel.randomPrimary}
            />
          </Grid>
        )}
        {player.SPP >= playerLevel.chosenPrimary && (
          <Grid item xs={12} md={6}>
            <SelectField
              fieldName="chosenPrimary"
              label="Add a Chosen Primary Skill"
              options={SKILLS.filter((skill) =>
                player.primary.includes(skill.type)
              )
                .filter((skill) => !player.skills.includes(skill.name))
                .map((skill) => ({
                  label: `${skill.name} (${skill.type})`,
                  value: skill.name,
                }))}
              fullWidth
              labelType="standalone"
            />
          </Grid>
        )}
        {player.SPP >= playerLevel.randomSecondary && (
          <Grid item xs={12} md={6}>
            <SelectField
              fieldName="randomSecondary"
              label="Add a Random Secondary Skill"
              options={SKILLS.filter((skill) =>
                player.secondary.includes(skill.type)
              )
                .filter((skill) => !player.skills.includes(skill.name))
                .map((skill) => ({
                  label: `${skill.name} (${skill.type})`,
                  value: skill.name,
                }))}
              fullWidth
              labelType="standalone"
            />
          </Grid>
        )}
        {player.SPP >= playerLevel.chosenSecondary && (
          <Grid item xs={12} md={6}>
            <SelectField
              fieldName="chosenSecondary"
              label="Add a Chosen Secondary Skill"
              options={SKILLS.filter((skill) =>
                player.secondary.includes(skill.type)
              )
                .filter((skill) => !player.skills.includes(skill.name))
                .map((skill) => ({
                  label: `${skill.name} (${skill.type})`,
                  value: skill.name,
                }))}
              fullWidth
              labelType="standalone"
            />
          </Grid>
        )}
        {player.SPP >= playerLevel.randomCharacteristic && (
          <Grid item xs={12} md={6}>
            <SelectField
              fieldName="randomCharacteristic"
              label="Add a Random Characteristic Advance"
              options={[
                { label: "MA", value: "MA" },
                { label: "AV", value: "AV" },
                { label: "PA", value: "PA" },
                { label: "AG", value: "AG" },
                { label: "ST", value: "ST" },
              ]}
              fullWidth
              labelType="standalone"
            />
          </Grid>
        )}
        {player.SPP < playerLevel.randomPrimary && (
          <Typography
            sx={{
              mb: 2,
              mt: 2,
              display: "block",
              textAlign: "center",
              width: "100%",
            }}
          >
            Not enough SPP for an advance.
          </Typography>
        )}
      </Grid>
      <Button
        variant="contained"
        fullWidth
        type="submit"
        sx={{ mb: 1 }}
        disabled={player.SPP < playerLevel.randomPrimary}
      >
        Save Changes
      </Button>
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        type="button"
        onClick={cancel}
      >
        Cancel
      </Button>
    </FormHarness>
  );
};

const EditPlayer = ({ roster, player, open, toggle }) => {
  const { owner } = useUser();
  const isOwner = owner.uid === roster.owner;
  const [editState, setEditState] = React.useState({
    editMeta: false,
    editSkills: false,
    editStats: false,
  });
  const toggleEditMetadata = () => {
    setEditState((prevState) => ({
      editMeta: !prevState.editMeta,
      editSkills: false,
      editStats: false,
    }));
  };
  const toggleEditSkills = () => {
    setEditState((prevState) => ({
      editMeta: false,
      editSkills: !prevState.editSkills,
      editStats: false,
    }));
  };
  const toggleEditStats = () => {
    setEditState((prevState) => ({
      editMeta: false,
      editSkills: false,
      editStats: !prevState.editStats,
    }));
  };
  const resetEdits = () => {
    setEditState({
      editMeta: false,
      editSkills: false,
      editStats: false,
    });
  };

  const playerLevel = LEVELS.find(({ level }) => level === player?.level + 1);

  const handleUpdatePlayer = (newPlayerData) => {
    resetEdits();
    toggle();
    updatePlayer(roster, {
      ...player,
      ...newPlayerData,
    });
  };

  return (
    <BasicDrawer anchor="bottom" open={open} onClose={toggle}>
      <DrawerContent>
        {editState.editMeta && (
          <EditPlayerForm
            player={player}
            handleSubmit={handleUpdatePlayer}
            cancel={toggleEditMetadata}
          />
        )}

        {editState.editSkills && (
          <EditPlayerSkillsForm
            player={player}
            handleSubmit={handleUpdatePlayer}
            playerLevel={playerLevel}
            cancel={toggleEditSkills}
          />
        )}

        {editState.editStats && (
          <EditPlayerStats
            player={player}
            handleSubmit={handleUpdatePlayer}
            playerLevel={playerLevel}
            cancel={toggleEditStats}
          />
        )}

        {!editState.editMeta && !editState.editSkills && !editState.editStats && (
          <>
            <PlayerMetadata
              player={player}
              playerLevel={playerLevel}
              handleEditPlayer={toggleEditMetadata}
              isOwner={isOwner}
            />
            <PlayerVitals player={player} />
            <PlayerSkills
              player={player}
              handleEditPlayer={toggleEditSkills}
              isOwner={isOwner}
            />
            <PlayerStats player={player} handleEditPlayer={toggleEditStats} />
            <PlayerInjuryHistory player={player} />

            {isOwner && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  firePlayer(roster, player.id);
                }}
                sx={{ mb: 2 }}
              >
                Fire Player
              </Button>
            )}
          </>
        )}
      </DrawerContent>
    </BasicDrawer>
  );
};

EditPlayer.defaultProps = {
  open: false,
};
EditPlayer.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  roster: RosterType,
  player: PlayerType,
};

export default EditPlayer;
