export const PLAYERS = {
	WILLIE: {
		name: 'Willie',
		team: 'Slann',
		division: 'HSDD',
		id: 'hikcBnBbUtWx7e5xmBBj2QVX2ii1',
	},
	TAJ: {
		name: 'Taj',
		team: 'Elven Union',
		division: 'HSDD',
		id: 'i6xnKCz5f8d8o157LFBSK9UZ8sF3',
	},
	JORDAN: {
		name: 'Jordan',
		team: 'Lizardmen',
		division: 'HSDD',
		id: 'xnhlNFi0CWfHdjZ3NFMqRWmHnAn1',
	},
	MITCH: {
		name: 'Mitch',
		team: 'Snotlings',
		division: 'HSDD',
		id: 'nBtb9Ur6ZGUSBu1NdwVvdM267lG2',
	},
	TIM: {
		name: 'Tim',
		team: 'Necromantic',
		division: 'HSDD',
		id: 'ds15C2VutFXKMvXxSwzJGelFpV22',
	},
	STEVE: {
		name: 'Steve',
		team: 'Khorne',
		division: 'HSDD',
		id: 'AbuT4tPsJXgC236b5d71NhAeHzg2',
	},
	RAY: {
		name: 'Ray',
		team: 'Black Orcs',
		division: 'HSDD',
		id: '3TtTvJLloUN4COYWLesdI2YDCms1',
	},
	CHRIS: {
		name: 'Chris',
		team: 'Dwarves',
		division: 'DSHD',
		id: 'qwW9jhVi0ZY2klN62u3Rg6mz2Rs1',
	},
	ELLE: {
		name: 'Elle',
		team: 'Chaos Renegades',
		division: 'DSHD',
		id: 'EIV72zcAhSMyOsNZrWsgRVBKQU23',
	},
	ALEX_H: {
		name: 'Alex H',
		team: 'Lizardmen',
		division: 'DSHD',
		id: 'bNfotrJ4PMguPE7k3uKdaNytD4X2',
	},
	KORY: {
		name: 'Kory',
		team: 'High Elves',
		division: 'DSHD',
		id: 'IBmI69AnaUWTFEC3HGPM8rSdvuu2',
	},
	ALEX_A: {
		name: 'Alex A',
		team: 'Black Orcs',
		division: 'DSHD',
		id: 'aZm5IkN470dHR0KssIaJOrCxSRm2',
	},
	JOHN: {
		name: 'John',
		team: 'Necromantic',
		division: 'DSHD',
		id: 'QDTXpFk9iVavaP8BDP4JW0OCWdW2',
	},
	ANDREW: {
		name: 'Andrew',
		team: 'Orcs',
		division: 'DSHD',
		id: '1GMOt6rza4Wat7hP0SqFa1zaFHi2',
	},
};

const SCHEDULE = [
	{
		label: 'Week 1',
		startDate: new Date(2022, 1, 14, 0, 0, 0, 0),
		endDate: new Date(2022, 1, 27, 23, 59, 59, 0),
		matches: [
			[PLAYERS.WILLIE, PLAYERS.TAJ],
			[PLAYERS.JORDAN, PLAYERS.RAY],
			[PLAYERS.MITCH, PLAYERS.STEVE],
			[PLAYERS.TIM, PLAYERS.ALEX_A],
			[PLAYERS.CHRIS, PLAYERS.ELLE],
			[PLAYERS.ALEX_H, PLAYERS.ANDREW],
			[PLAYERS.KORY, PLAYERS.JOHN],
		],
	},
	{
		label: 'Week 2',
		startDate: new Date(2022, 1, 28, 0, 0, 0, 0),
		endDate: new Date(2022, 2, 13, 23, 59, 59, 0),
		matches: [
			[PLAYERS.WILLIE, PLAYERS.RAY],
			[PLAYERS.TAJ, PLAYERS.STEVE],
			[PLAYERS.JORDAN, PLAYERS.TIM],
			[PLAYERS.MITCH, PLAYERS.KORY],
			[PLAYERS.CHRIS, PLAYERS.ANDREW],
			[PLAYERS.ELLE, PLAYERS.JOHN],
			[PLAYERS.ALEX_A, PLAYERS.ALEX_H],
		],
	},
	{
		label: 'Week 3',
		startDate: new Date(2022, 2, 14, 0, 0, 0, 0),
		endDate: new Date(2022, 2, 27, 23, 59, 59, 0),
		matches: [
			[PLAYERS.WILLIE, PLAYERS.CHRIS],
			[PLAYERS.TAJ, PLAYERS.RAY],
			[PLAYERS.JORDAN, PLAYERS.STEVE],
			[PLAYERS.MITCH, PLAYERS.TIM],
			[PLAYERS.ELLE, PLAYERS.ANDREW],
			[PLAYERS.ALEX_H, PLAYERS.JOHN],
			[PLAYERS.KORY, PLAYERS.ALEX_A],
		],
	},
	{
		label: 'Week 4',
		startDate: new Date(2022, 2, 28, 0, 0, 0, 0),
		endDate: new Date(2022, 3, 10, 23, 59, 59, 0),
		matches: [
			[PLAYERS.WILLIE, PLAYERS.MITCH],
			[PLAYERS.TAJ, PLAYERS.JORDAN],
			[PLAYERS.TIM, PLAYERS.RAY],
			[PLAYERS.STEVE, PLAYERS.JOHN],
			[PLAYERS.CHRIS, PLAYERS.KORY],
			[PLAYERS.ELLE, PLAYERS.ALEX_H],
			[PLAYERS.ANDREW, PLAYERS.ALEX_A],
		],
	},
	{
		label: 'Week 5',
		startDate: new Date(2022, 3, 11, 0, 0, 0, 0),
		endDate: new Date(2022, 3, 24, 23, 59, 59, 0),
		matches: [
			[PLAYERS.WILLIE, PLAYERS.ALEX_H],
			[PLAYERS.TAJ, PLAYERS.KORY],
			[PLAYERS.JORDAN, PLAYERS.CHRIS],
			[PLAYERS.MITCH, PLAYERS.ANDREW],
			[PLAYERS.TIM, PLAYERS.JOHN],
			[PLAYERS.STEVE, PLAYERS.ELLE],
			[PLAYERS.RAY, PLAYERS.ALEX_A],
		],
	},
	{
		label: 'Week 6',
		startDate: new Date(2022, 3, 25, 0, 0, 0, 0),
		endDate: new Date(2022, 4, 8, 23, 59, 59, 0),
		matches: [
			[PLAYERS.TIM, PLAYERS.WILLIE],
			[PLAYERS.TAJ, PLAYERS.MITCH],
			[PLAYERS.JORDAN, PLAYERS.ALEX_H],
			[PLAYERS.STEVE, PLAYERS.RAY],
			[PLAYERS.CHRIS, PLAYERS.ALEX_A],
			[PLAYERS.ELLE, PLAYERS.KORY],
			[PLAYERS.JOHN, PLAYERS.ANDREW],
		],
	},
	{
		label: 'Week 7',
		startDate: new Date(2022, 4, 9, 0, 0, 0, 0),
		endDate: new Date(2022, 4, 22, 23, 59, 59, 0),
		matches: [
			[PLAYERS.WILLIE, PLAYERS.JORDAN],
			[PLAYERS.TAJ, PLAYERS.ELLE],
			[PLAYERS.MITCH, PLAYERS.RAY],
			[PLAYERS.TIM, PLAYERS.STEVE],
			[PLAYERS.CHRIS, PLAYERS.ALEX_H],
			[PLAYERS.ANDREW, PLAYERS.KORY],
			[PLAYERS.JOHN, PLAYERS.ALEX_A],
		],
	},
	{
		label: 'Week 8',
		startDate: new Date(2022, 4, 23, 0, 0, 0, 0),
		endDate: new Date(2022, 5, 5, 23, 59, 59, 0),
		matches: [
			[PLAYERS.WILLIE, PLAYERS.STEVE],
			[PLAYERS.TAJ, PLAYERS.TIM],
			[PLAYERS.JORDAN, PLAYERS.MITCH],
			[PLAYERS.RAY, PLAYERS.ANDREW],
			[PLAYERS.ELLE, PLAYERS.ALEX_A],
			[PLAYERS.CHRIS, PLAYERS.JOHN],
			[PLAYERS.KORY, PLAYERS.ALEX_H],
		],
	},
];

export default SCHEDULE;
