import React from 'react';
import {
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Divider,
	Typography,
} from '@mui/material';

const MatchList = ({ matches, users, selectMatch }) => {
	return (
		<List>
			<Divider />
			{matches.length ? (
				matches.map((match) => {
					const opponent = users.find(
						(user) => !user.id.localeCompare(match.opponent)
					);
					return (
						<div key={match.id}>
							<ListItem disablePadding>
								<ListItemButton onClick={() => selectMatch(match)}>
									<ListItemText
										primary={`${match.type} vs ${opponent.fname} ${opponent.lname}`}
										secondary={`Started on: ${new Date(
											match.matchStartDate
										).toLocaleDateString('en-US')}`}
									/>
								</ListItemButton>
							</ListItem>
							<Divider sx={{ mb: 2 }} />
						</div>
					);
				})
			) : (
				<>
					<Typography sx={{ mb: 1, mt: 1, textAlign: 'center' }}>
						You have no active matches...
					</Typography>
					<Divider sx={{ mb: 2 }} />
				</>
			)}
		</List>
	);
};

export default MatchList;
