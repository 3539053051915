import React from "react";
import {
  Container,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Paper,
  Typography,
} from "@mui/material";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { database } from "../../services/fire";
import Loading from "../../components/Loader";

const BestInStatDisplay = ({ players, stat }) => {
  return (
    <Typography variant="body2">
      ({players[0].stats[stat]}){" "}
      {players.map(
        (player, idx, self) =>
          `${player.name}${
            self.length > 1 && idx + 1 !== self.length ? ", " : ""
          }`
      )}
    </Typography>
  );
};

const Stats = () => {
  const [rosters, rostersLoading, rostersError] = useCollectionDataOnce(
    database.collection("rosters3"),
    { idField: "id" }
  );

  if (rostersLoading) return <Loading />;
  if (rostersError) return <pre>An unexpected error occurred.</pre>;

  const players = rosters.map((roster) => roster.players).flat();

  const getBestInStatCategory = (category, data) => {
    return data.reduce(
      (acc, player) => {
        if (player.stats[category] === acc[0].stats[category])
          return [...acc, player];
        if (player.stats[category] > acc[0].stats[category]) return [player];
        return acc;
      },
      [data[0]]
    );
  };

  return (
    <Container maxWidth="md" sx={{ padding: { xs: 0, sm: "inherit" } }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        League Leaders
      </Typography>
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2} sx={{ fontWeight: "bold" }}>
                Statistics
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Casualties (CAS)</TableCell>
              <TableCell>
                <BestInStatDisplay
                  stat="CAS"
                  players={getBestInStatCategory("CAS", players)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Deflections (DEF)</TableCell>
              <TableCell>
                <BestInStatDisplay
                  stat="DEF"
                  players={getBestInStatCategory("DEF", players)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Interceptions (INT)</TableCell>
              <TableCell>
                <BestInStatDisplay
                  stat="INT"
                  players={getBestInStatCategory("INT", players)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Most Valuable Player (MVP)</TableCell>
              <TableCell>
                <BestInStatDisplay
                  stat="MVP"
                  players={getBestInStatCategory("MVP", players)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Completed Passes (PAS)</TableCell>
              <TableCell>
                <BestInStatDisplay
                  stat="PAS"
                  players={getBestInStatCategory("PAS", players)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Completed Throws (THR)</TableCell>
              <TableCell>
                <BestInStatDisplay
                  stat="THR"
                  players={getBestInStatCategory("THR", players)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Touchdowns (TD)</TableCell>
              <TableCell>
                <BestInStatDisplay
                  stat="TD"
                  players={getBestInStatCategory("TD", players)}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default Stats;
