import React from 'react';
import {
	Stepper,
	Step,
	StepLabel,
	Typography,
	Button,
	Grid,
	Stack,
	Divider,
} from '@mui/material';
import { useHistory } from 'react-router-dom';

const Brackets = ({ matches, rosters, players }) => {
	const history = useHistory();
	const [currentRound, setCurrentRound] = React.useState(0);
	const handleNext = () => {
		setCurrentRound((prevActiveStep) => {
			if (prevActiveStep === 3) return prevActiveStep;
			return prevActiveStep + 1;
		});
	};
	const handlePrev = () => {
		setCurrentRound((prevActiveStep) => {
			if (prevActiveStep === 0) return prevActiveStep;
			return prevActiveStep - 1;
		});
	};
	const navigate = (path) => {
		history.push(path);
	};

	return (
		<>
			<Typography variant="h5" sx={{ mb: 3 }}>
				Playoff Round {currentRound + 1}
			</Typography>
			{matches
				.filter((match) => match.round === currentRound + 1)
				.map((match, index) => {
					const getRosterId = (player) => {
						const roster = rosters.find((r) => r.owner === player?.id);
						navigate(`/team/${roster.id}`);
					};
					return (
						<>
							<Grid container spacing={1}>
								<Grid item xs={4}>
									<Typography variant="h6">Match {index + 1}:</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography
										onClick={() => getRosterId(match?.players[0])}
										sx={{
											fontWeight:
												match?.winner === match?.players[0] ? 'bold' : 'normal',
										}}
									>
										{match?.players[0]?.name || 'TBD'}
									</Typography>
								</Grid>
								<Grid item xs={2}>
									<Typography sx={{ textAlign: 'center' }}>
										{match?.winner === ''
											? 'vs.'
											: match?.winner === match?.players[0]
											? 'defeated'
											: 'defeated by'}
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography
										onClick={() => getRosterId(match?.players[1])}
										sx={{
											fontWeight:
												match?.winner === match?.players[1] ? 'bold' : 'normal',
											textAlign: 'right',
										}}
									>
										{match?.players[1]?.name || 'TBD'}
									</Typography>
								</Grid>
							</Grid>
							<Divider sx={{ mb: 2, mt: 1 }} />
						</>
					);
				})}
			<Stack sx={{ mt: 3 }}>
				<Button
					variant="contained"
					color="secondary"
					onClick={handlePrev}
					sx={{ mb: 2 }}
					disabled={currentRound === 0}
				>
					Previous Round
				</Button>
				<Button
					variant="contained"
					color="primary"
					onClick={handleNext}
					disabled={currentRound === 3}
				>
					Next Round
				</Button>
			</Stack>
		</>
	);
};

export default Brackets;
