const D = (sides) => Math.floor(Math.random() * (sides - 1) + 1);
const twoD = (sides) => {
	const d1 = D(sides);
	const d2 = D(sides);
	return d1 + d2;
};

const EXPENSIVE_MISTAKES_DISPOSITIONS = {
	CRISIS_AVERTED: (treasury) => ({
		label: 'Crisis Averted',
		value: treasury,
	}),
	MINOR_INCIDENT: (treasury) => ({
		label: 'Minor Incident',
		value: treasury - D(3) * 10000,
	}),
	MAJOR_INCIDENT: (treasury) => ({
		label: 'Major Incident',
		value: treasury * 0.5,
	}),
	CATASTROPHE: () => ({
		label: 'Catastrophe',
		value: twoD(6) * 10000,
	}),
};

const EXPENSIVE_MISTAKES_TABLE = {
	_100k_195k: [
		EXPENSIVE_MISTAKES_DISPOSITIONS.MINOR_INCIDENT,
		EXPENSIVE_MISTAKES_DISPOSITIONS.CRISIS_AVERTED,
		EXPENSIVE_MISTAKES_DISPOSITIONS.CRISIS_AVERTED,
		EXPENSIVE_MISTAKES_DISPOSITIONS.CRISIS_AVERTED,
		EXPENSIVE_MISTAKES_DISPOSITIONS.CRISIS_AVERTED,
		EXPENSIVE_MISTAKES_DISPOSITIONS.CRISIS_AVERTED,
	],
	_200k_295k: [
		EXPENSIVE_MISTAKES_DISPOSITIONS.MINOR_INCIDENT,
		EXPENSIVE_MISTAKES_DISPOSITIONS.MINOR_INCIDENT,
		EXPENSIVE_MISTAKES_DISPOSITIONS.CRISIS_AVERTED,
		EXPENSIVE_MISTAKES_DISPOSITIONS.CRISIS_AVERTED,
		EXPENSIVE_MISTAKES_DISPOSITIONS.CRISIS_AVERTED,
		EXPENSIVE_MISTAKES_DISPOSITIONS.CRISIS_AVERTED,
	],
	_300k_395k: [
		EXPENSIVE_MISTAKES_DISPOSITIONS.MAJOR_INCIDENT,
		EXPENSIVE_MISTAKES_DISPOSITIONS.MINOR_INCIDENT,
		EXPENSIVE_MISTAKES_DISPOSITIONS.MINOR_INCIDENT,
		EXPENSIVE_MISTAKES_DISPOSITIONS.CRISIS_AVERTED,
		EXPENSIVE_MISTAKES_DISPOSITIONS.CRISIS_AVERTED,
		EXPENSIVE_MISTAKES_DISPOSITIONS.CRISIS_AVERTED,
	],
	_400k_495k: [
		EXPENSIVE_MISTAKES_DISPOSITIONS.MAJOR_INCIDENT,
		EXPENSIVE_MISTAKES_DISPOSITIONS.MAJOR_INCIDENT,
		EXPENSIVE_MISTAKES_DISPOSITIONS.MINOR_INCIDENT,
		EXPENSIVE_MISTAKES_DISPOSITIONS.MINOR_INCIDENT,
		EXPENSIVE_MISTAKES_DISPOSITIONS.CRISIS_AVERTED,
		EXPENSIVE_MISTAKES_DISPOSITIONS.CRISIS_AVERTED,
	],
	_500k_595k: [
		EXPENSIVE_MISTAKES_DISPOSITIONS.CATASTROPHE,
		EXPENSIVE_MISTAKES_DISPOSITIONS.MAJOR_INCIDENT,
		EXPENSIVE_MISTAKES_DISPOSITIONS.MAJOR_INCIDENT,
		EXPENSIVE_MISTAKES_DISPOSITIONS.MINOR_INCIDENT,
		EXPENSIVE_MISTAKES_DISPOSITIONS.MINOR_INCIDENT,
		EXPENSIVE_MISTAKES_DISPOSITIONS.CRISIS_AVERTED,
	],
	_600k_PLUS: [
		EXPENSIVE_MISTAKES_DISPOSITIONS.CATASTROPHE,
		EXPENSIVE_MISTAKES_DISPOSITIONS.CATASTROPHE,
		EXPENSIVE_MISTAKES_DISPOSITIONS.MAJOR_INCIDENT,
		EXPENSIVE_MISTAKES_DISPOSITIONS.MAJOR_INCIDENT,
		EXPENSIVE_MISTAKES_DISPOSITIONS.MINOR_INCIDENT,
		EXPENSIVE_MISTAKES_DISPOSITIONS.MINOR_INCIDENT,
	],
};

const expensiveMistakes = (treasury) => {
	let riskTable;

	if (treasury >= 100000 && treasury <= 195000) {
		riskTable = EXPENSIVE_MISTAKES_TABLE['_100k_195k'];
	} else if (treasury >= 200000 && treasury <= 295000) {
		riskTable = EXPENSIVE_MISTAKES_TABLE['_200k_295k'];
	} else if (treasury >= 300000 && treasury <= 395000) {
		riskTable = EXPENSIVE_MISTAKES_TABLE['_300k_395k'];
	} else if (treasury >= 400000 && treasury <= 495000) {
		riskTable = EXPENSIVE_MISTAKES_TABLE['_400k_495k'];
	} else if (treasury >= 500000 && treasury <= 595000) {
		riskTable = EXPENSIVE_MISTAKES_TABLE['_500k_595k'];
	} else if (treasury >= 600000) {
		riskTable = EXPENSIVE_MISTAKES_TABLE['_600k_PLUS'];
	} else {
		riskTable = [];
	}

	const randomRoll = D(6);

	if (riskTable.length) {
		const result = riskTable[randomRoll - 1](treasury);
		return {
			incidentLabel: result.label,
			oldTreasury: treasury,
			newTreasury: result.value,
		};
	} else {
		return {
			incidentLabel: 'No Expensive Mistakes',
			oldTreasury: treasury,
			newTreasury: treasury,
		};
	}
};

export default expensiveMistakes;
