import React, { createContext, useContext } from "react";
import {
  useCollectionData,
  useCollectionDataOnce,
} from "react-firebase-hooks/firestore";
import { useAuth } from "./AuthContext";
import { database } from "../services/fire";
import Error from "../components/Error";
import Loading from "../components/Loader";

const RosterContext = createContext();

export function useRoster() {
  return useContext(RosterContext);
}

export function RosterProvider({ children }) {
  const { user } = useAuth();
  const [rosters, loading, error] = useCollectionData(
    database.collection("rosters3"),
    { idField: "id" }
  );
  const [staff, staffLoading, staffError] = useCollectionDataOnce(
    database.collection("staff")
  );
  const [teams, teamsLoading, teamsError] = useCollectionDataOnce(
    database.collection("teams"),
    { idField: "id" }
  );

  if (error || staffError || teamsError) return <Error />;

  if (!user) return <Loading />;

  // Get all rosters associated with the current user
  const ownRosters =
    Array.isArray(rosters) && !!user
      ? rosters.filter((roster) => roster.owner === user.uid)
      : [];

  const getRosterById = (rosterId) => {
    return rosters.find((roster) => roster.id === rosterId);
  };

  return (
    <RosterContext.Provider
      value={{
        allRosters: rosters,
        staff,
        teams,
        ownRosters,
        getRosterById,
      }}
    >
      {loading || staffLoading || teamsLoading ? <Loading /> : children}
    </RosterContext.Provider>
  );
}
