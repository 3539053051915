import PropTypes from 'prop-types';

const record = PropTypes.shape({
	win: PropTypes.number.isRequired,
	loss: PropTypes.number.isRequired,
	draw: PropTypes.number.isRequired,
});

export const createRecord = (defaults) => ({
	win: defaults?.win || 0,
	loss: defaults?.win || 0,
	draw: defaults?.draw || 0,
});

export default record;
