import React from 'react';
import { Box, Typography, Card, CardContent, Button } from '@mui/material';
import { styled } from '@mui/system';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import FormHarness from '../../components/FormControls/Harness';
import TextInput from '../../components/FormControls/TextInput';

const LoginWrapper = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	minHeight: '100vh',
	backgroundColor: theme.palette.background.default,
}));

const LoginCard = styled(Card)(({ theme }) => ({
	minWidth: '33vw',
}));

const Login = () => {
	const { login, user } = useAuth();
	const history = useHistory();

	const handleLink = (path) => {
		history.push(path);
	};

	if (user !== null) {
		return <Redirect to="/" />;
	}

	return (
		<LoginWrapper>
			<LoginCard>
				<CardContent>
					<Typography variant="h4" align="center" mb={2}>
						Sign In
					</Typography>
					<FormHarness handleFormSubmit={login}>
						<TextInput fieldName="email" label="Email Address" />
						<TextInput fieldName="password" label="Password" type="password" />
						<Button type="submit" variant="contained" fullWidth>
							Login
						</Button>
					</FormHarness>
					<Button
						variant="contained"
						color="secondary"
						fullWidth
						onClick={() => handleLink(`/signup`)}
						sx={{ mt: 1 }}
					>
						Signup
					</Button>
				</CardContent>
			</LoginCard>
		</LoginWrapper>
	);
};

export default Login;
