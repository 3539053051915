export const getInitials = (fname, lname) => {
	return `${fname.charAt(0).toUpperCase()}${lname.charAt(0).toUpperCase()}`;
};

export const acronymize = (string) => {
	const matches = string.match(/\b(\w)/g);
	return matches.join('');
};

function stringToColor(string) {
	let hash = 0;
	let i;

	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash);
	}

	let color = '#';

	for (i = 0; i < 3; i += 1) {
		const value = (hash >> (i * 8)) & 0xff;
		color += `00${value.toString(16)}`.substr(-2);
	}

	return color;
}

export const stringAvatar = (name, addlStyles) => {
	return {
		sx: {
			backgroundColor: stringToColor(name),
			...addlStyles,
		},
		children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
	};
};

export const truncate = {
	sx: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
};

export const parseCost = (cost) => {
	try {
		return `${cost.toLocaleString('en-us')}gp`;
	} catch (e) {
		return '0gp';
	}
};
