import React from "react";
import { Container, Button, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useUser } from "../../contexts/UserContext";
import { useRoster } from "../../contexts/RosterContext";
import RosterCardMini from "../../components/RosterCards/Mini";

const MyTeam = () => {
  const { ownRosters } = useRoster();
  const { owner } = useUser();
  const history = useHistory();

  const handleLink = (path) => {
    history.push(path);
  };

  // If the user has exactly one roster
  if (ownRosters.length === 1) {
    handleLink(`/team/${ownRosters[0].id}`);
  }

  return (
    <Container maxWidth="md">
      {ownRosters.length ? (
        ownRosters.map((roster) => (
          <div onClick={() => handleLink(`/team/${roster.id}`)} key={roster.id}>
            <RosterCardMini roster={roster} owner={owner} key={roster.id} />
          </div>
        ))
      ) : (
        <>
          <Typography variant="h5" sx={{ mb: 2 }}>
            My Teams
          </Typography>
          <Typography sx={{ mb: 2 }}>
            You haven't created a team yet...
          </Typography>
        </>
      )}
      <Button
        variant="contained"
        onClick={() => handleLink("/create-team")}
        fullWidth
        disabled={ownRosters.length}
      >
        Create New Roster
      </Button>
    </Container>
  );
};

export default MyTeam;
