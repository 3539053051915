import React, { createContext, useContext } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useAuth } from "./AuthContext";
import { database } from "../services/fire";
import Error from "../components/Error";
import Loading from "../components/Loader";

const UserContext = createContext();

export function useUser() {
  return useContext(UserContext);
}

export function UserProvider({ children }) {
  const { user } = useAuth();
  const email = !!user ? user.email : "";
  const [owner, loading, error] = useCollectionData(
    database.collection("users").where("email", "==", email),
    { idField: "id" }
  );

  if (error) return <Error />;

  if (loading || !owner) return <Loading />;

  return (
    <UserContext.Provider value={{ owner: owner[0] }}>
      {loading ? <Loading /> : children}
    </UserContext.Provider>
  );
}
