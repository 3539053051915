import React from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
} from '@mui/material';
import { parseCost } from '../../../services/textParser';

const MatchSummaryDialog = ({ defaultOpen, results, handleCloseDialog }) => {
	const {
		newTreasury,
		winnings,
		expensiveMistakesDisposition,
		oldDedicatedFans,
		newDedicatedFans,
		disposition,
	} = results;
	const gainedAFan = newDedicatedFans > oldDedicatedFans;
	const lostAFan = newDedicatedFans < oldDedicatedFans;

	return (
		<Dialog open={defaultOpen}>
			<DialogTitle>Match Summary</DialogTitle>
			<DialogContent>
				<DialogContentText sx={{ mb: 1 }}>
					{!disposition.localeCompare('W') &&
						'Congratulations on winning your match!'}
					{!disposition.localeCompare('L') && 'Better luck next time... '}
					{!disposition.localeCompare('D') && "It's a draw!"}
				</DialogContentText>
				<DialogContentText sx={{ mb: 1 }}>
					{!disposition.localeCompare('W') &&
						gainedAFan &&
						`Your glorious victory has earned you a dedicated fan! You now have ${newDedicatedFans} fans.`}
					{!disposition.localeCompare('W') &&
						!gainedAFan &&
						`The fans were satisfied with your victory but not thrilled. Your total dedicated fans remain at ${newDedicatedFans}.`}
					{!disposition.localeCompare('L') &&
						lostAFan &&
						`Your loss greatly upset the crowd. You lost a dedicated fan; ${newDedicatedFans} fans remain.`}
					{!disposition.localeCompare('L') &&
						!lostAFan &&
						`Despite your loss your fans thought you played well. Your total fans remain at ${newDedicatedFans}.`}
					{!disposition.localeCompare('D') &&
						`Your fans aren't exactly happy with your tie...but they aren't exactly sad either. You retain your fanbase of ${newDedicatedFans} fans.`}
				</DialogContentText>
				{expensiveMistakesDisposition.oldTreasury !==
					expensiveMistakesDisposition.newTreasury && (
					<DialogContentText sx={{ mb: 1 }}>
						A full treasure room attracts...unwanted...attention. Some of your
						players have made an Expensive Mistake!
						<br />
						{!expensiveMistakesDisposition.incidentLabel.localeCompare(
							'Crisis Averted'
						) &&
							`Despite their drunken post-game attempts to pilfer your treasure room your players were not able to loot anything of value: Crisis Averted!`}
						{!expensiveMistakesDisposition.incidentLabel.localeCompare(
							'Minor Incident'
						) &&
							`Your players were able to make-off with a paltry ${parseCost(
								expensiveMistakesDisposition.oldTreasury -
									expensiveMistakesDisposition.newTreasury
							)}...a Minor Incident.`}
						{!expensiveMistakesDisposition.incidentLabel.localeCompare(
							'Major Incident'
						) &&
							`Your players were left unattended for some time in your treasure room and stole the princely sum of ${parseCost(
								expensiveMistakesDisposition.oldTreasury -
									expensiveMistakesDisposition.newTreasury
							)}! A Major Incident!`}
						{!expensiveMistakesDisposition.incidentLabel.localeCompare(
							'Catastrophe'
						) &&
							`It. Is. Terrible. Your players have emptied your entire treasury leaving only ${parseCost(
								expensiveMistakesDisposition.newTreasury
							)}...An utter Catastrophe.`}
					</DialogContentText>
				)}
				<DialogContentText sx={{ mb: 1 }}>
					Your team collected {parseCost(winnings)} in winnings bringing your
					new treasury total to {parseCost(newTreasury)}.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCloseDialog}>Close Match Summary</Button>
			</DialogActions>
		</Dialog>
	);
};

export default MatchSummaryDialog;
