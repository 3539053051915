import PropTypes, { shape, arrayOf } from 'prop-types';
import RosterItemType from './rosterItem';
import RecordType, { createRecord } from './record';

const roster = shape({
	dateCreated: PropTypes.number.isRequired,
	id: PropTypes.string.isRequired,
	initialized: PropTypes.bool.isRequired,
	items: arrayOf(RosterItemType),
	lastUpdatedAt: PropTypes.number.isRequired,
	owner: PropTypes.string.isRequired,
	specialRules: arrayOf(PropTypes.string),
	factionId: PropTypes.string.isRequired,
	factionName: PropTypes.string.isRequired,
	teamName: PropTypes.string.isRequired,
	treasury: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
	players: arrayOf(PropTypes.string),
	record: RecordType,
	friendlyRecord: RecordType,
	leaguePoints: PropTypes.number.isRequired,
	leagueId: PropTypes.string.isRequired,
	dedicatedFans: PropTypes.number.isRequired,
});

const rosterValuation = (roster) => {
	// Assess player value
	const playerValue = roster.players.reduce((total, player) => {
		const { baseCost } = player;
		const advancements = player.advancements.reduce(
			(acc, adv) => acc + adv.cost,
			0
		);
		return total + baseCost + advancements;
	}, 0);

	// Assess staff value
	const staffValue = roster.items.reduce((total, staff) => {
		if (!staff.label.localeCompare('Extra Fans')) {
			return total;
		}
		const staffCost = staff.value * staff.qty;
		return total + staffCost;
	}, 0);

	return playerValue + staffValue;
};

const validateRoster = (roster, league) => {
	const playerCount = roster.players.length;
	const rosterValue = rosterValuation(roster);
	const leagueStartingValue = league.rosterStartingValue;

	const requirements = [
		playerCount >= 11,
		playerCount <= 16,
		rosterValue <= leagueStartingValue,
	];

	return requirements.every((req) => !!req);
};

export const createNewRoster = (
	leagueData,
	teamData,
	playerData,
	itemData,
	owner
) => {
	const extraFans = itemData.find(
		(item) => !item.label.localeCompare('Extra Fans')
	);
	const roster = {
		dateCreated: Date.now(),
		id: teamData.id,
		initialized: true,
		items: itemData,
		lastUpdatedAt: Date.now(),
		owner,
		specialRules: teamData.specialRules,
		factionId: teamData.factionId,
		factionName: teamData.name,
		teamName: teamData.teamName,
		players: playerData,
		record: createRecord(),
		friendlyRecord: createRecord(),
		leaguePoints: 0,
		leagueId: teamData.leagueId,
		dedicatedFans: 1 + parseInt(extraFans?.qty || 0),
	};

	// Set treasury and team value after initial creation
	const teamValue = rosterValuation(roster);
	const extraFansValue =
		parseInt(extraFans.qty) * parseInt(extraFans.value) || 0;
	roster.treasury = leagueData.rosterStartingValue - teamValue - extraFansValue;
	roster.value = teamValue;

	// Validate Roster
	const rosterIsValid = validateRoster(roster, leagueData);

	if (!rosterIsValid) return { ...roster, valid: false };
	return {
		...roster,
		valid: true,
	};
};

export default roster;
