import React from 'react';
import {
	useCollectionDataOnce,
	useCollectionData,
} from 'react-firebase-hooks/firestore';
import { useHistory } from 'react-router-dom';
import { Container, Typography } from '@mui/material';
import { database } from '../../services/fire';
import { useUser } from '../../contexts/UserContext';
import { useRoster } from '../../contexts/RosterContext';
import Loading from '../../components/Loader';
import Error from '../../components/Error';
import MatchList from '../../components/MatchManagement/MatchList';
import NewMatch from '../../components/MatchManagement/NewMatch';
import { createNewMatch, startMatch } from '../../types/match';
import logEvent from '../../services/eventlog';

const MatchManager = () => {
	const { ownRosters } = useRoster();
	const { owner } = useUser();
	const history = useHistory();
	const navigate = (to) => history.push(to);

	// Get Users
	const [users, usersLoading, usersError] = useCollectionDataOnce(
		database.collection('users'),
		{ idField: 'id' }
	);

	// Get Matches
	const [matches, matchesLoading, matchesError] = useCollectionData(
		database.collection('matches'),
		{ idField: 'id' }
	);

	// Handle Async
	if (matchesError || usersError) return <Error />;
	if (matchesLoading || usersLoading) return <Loading />;

	// Filter match list to include only those relevant to the current user
	const myActiveMatches = matches.filter((match) => {
		const isInProgress = !match.status.localeCompare('Draft');
		const isOwnMatch = !match.playerData.user.localeCompare(owner.uid);

		return isInProgress && isOwnMatch;
	});
	const myCompleteMatches = matches.filter((match) => {
		const isComplete = match.status.localeCompare('Draft');
		const isOwnMatch = !match.playerData.user.localeCompare(owner.uid);

		return isComplete && isOwnMatch;
	});

	// Handle create new match
	const handleCreateNewMatch = (opponentId, type) => {
		const match = createNewMatch(ownRosters[0], owner.uid, opponentId, type);
		startMatch(match);

		const playerA = users.find((user) => user.id === owner.uid);
		const playerB = users.find((user) => user.id === opponentId);
		logEvent(
			'MATCH_START',
			`The match between ${playerA.fname} ${playerA.lname} and ${playerB.fname} ${playerB.lname} has started!`
		);
	};

	const handleMatchSelect = (match) => {
		navigate(`/matchup/${match.id}`);
	};

	if (!ownRosters.length) {
		return (
			<Container maxWidth="md">
				<Typography variant="h5" sx={{ mt: 1, mb: 2 }}>
					My Matches
				</Typography>
				<Typography>You haven't created a team yet...</Typography>
			</Container>
		);
	}

	return (
		<Container maxWidth="md">
			<Typography variant="h5" sx={{ mt: 1, mb: 2 }}>
				My Matches
			</Typography>
			<MatchList
				matches={myActiveMatches}
				users={users}
				selectMatch={handleMatchSelect}
			/>
			{myActiveMatches.length < 2 && (
				<NewMatch
					users={users}
					handleMatchStart={handleCreateNewMatch}
					myMatches={myActiveMatches}
					myCompleteMatches={myCompleteMatches}
					ownerId={owner.uid}
				/>
			)}
		</Container>
	);
};

export default MatchManager;
