import React from 'react';
import { Alert, AlertTitle } from '@mui/material';

const ErrorMessage = (props) => {
	const { message, dismissable } = props;

	return (
		<Alert severity="error" onClose={dismissable ? () => {} : undefined}>
			<AlertTitle>Error</AlertTitle>
			{!!message ? message : 'An unexpected error has occurred.'}
		</Alert>
	);
};

export default ErrorMessage;
