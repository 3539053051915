const D = (sides) => Math.floor(Math.random() * (sides - 1) + 1);

/**
 * Return a new Fan Factor value post-game
 * @param {String} gameResult
 * @param {Integer} currentFanFactor
 * @returns {Integer}
 */
const updateDedicatedFans = (gameResult, currentFanFactor) => {
	const dieResult = D(6);
	let newFanFactor = 0;

	switch (gameResult) {
		case 'W':
			newFanFactor =
				dieResult >= currentFanFactor ? currentFanFactor + 1 : currentFanFactor;
			break;
		case 'L':
			newFanFactor =
				dieResult < currentFanFactor ? currentFanFactor + 1 : currentFanFactor;
			break;
		default:
			newFanFactor = currentFanFactor;
			break;
	}

	return newFanFactor >= 1 ? newFanFactor : 1;
};

export default updateDedicatedFans;
