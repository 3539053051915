import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { useHistory } from "react-router-dom";
import { database } from "../services/fire";
import { auth } from "../services/fire";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const history = useHistory();

  // Business Logic
  const login = ({ email, password }) => {
    auth.signInWithEmailAndPassword(email, password);
  };
  const logout = useCallback(() => {
    setUser(null);
    history.push("/login");
    auth.signOut();
  }, [history]);
  const signup = async ({ email, password, fname, lname }) => {
    const newUser = await auth.createUserWithEmailAndPassword(email, password);
    const usersRef = await database // eslint-disable-line
      .collection("users")
      .doc(newUser.user.uid)
      .set({
        fname,
        lname,
        email,
        uid: newUser.user.uid,
      });
  };

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user === null) {
        logout();
      } else {
        setUser(user);
      }
    });
  }, [history, logout]);

  // Value to pass to components
  const value = {
    user,
    login,
    logout,
    signup,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
