import React from 'react';
import { Modal, CircularProgress, Box } from '@mui/material';

const Loading = () => {
	return (
		<Modal open={true}>
			<Box
				sx={{
					height: '100%',
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<CircularProgress />
			</Box>
		</Modal>
	);
};

export default Loading;
