import React from 'react';
import { useParams } from 'react-router-dom';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { database } from '../../services/fire';
import { useRoster } from '../../contexts/RosterContext';
import TeamManagement from '../../components/TeamManagement';
import Loading from '../../components/Loader';
import Error from '../../components/Error';

const MyTeam = () => {
	const { getRosterById } = useRoster();
	const { rosterId } = useParams();
	const roster = getRosterById(rosterId);

	// Get Players
	const [positions, posLoading, posError] = useCollectionDataOnce(
		database
			.collection('players')
			.where('team', '==', `${roster.factionName} Teams`)
	);

	// Get Matches
	const [matches, matchesLoading, matchesError] = useCollectionDataOnce(
		database.collection('matches'),
		{ idField: 'id' }
	);

	if (posError || matchesError) return <Error />;

	return posLoading || matchesLoading ? (
		<Loading />
	) : (
		<TeamManagement roster={{ ...roster, positions }} matches={matches} />
	);
};

export default MyTeam;
