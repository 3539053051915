import React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import FormHarness from '../../FormControls/Harness/index';
import TextField from '../../FormControls/TextInput/index';
import { createPlayer } from '../../../types/player';

const Pregame = ({ roster, opponentRoster, updateRoster }) => {
	// Handle Journeymen
	const [teamValue, setTeamValue] = React.useState(roster.value);
	const journeymanPositions = roster.positions.filter((pos) => pos.max >= 12);
	const needsJourneymen =
		roster.players.reduce(
			(total, player) => (player.MNG ? total : total + 1),
			0
		) < 11;
	const handleChangeJourneymen = (e, position) => {
		const numJourneymen = e.target.value;
		setTeamValue(parseInt(roster.value) + numJourneymen * position.cost);
	};

	const handleUpdateRoster = (formData) => {
		const journeymanPlayers = [];
		journeymanPositions.forEach((pos) => {
			const count = formData[pos.position];
			const playerData = roster.positions.find(
				({ position }) => pos.position === position
			);
			for (let index = 0; index < count; index++) {
				const journeyman = createPlayer(
					playerData,
					roster.id,
					roster.factionId
				);
				journeymanPlayers.push({
					...journeyman,
					name: `Journeyman Chump #${index + 1}`,
					isJourneyman: true,
				});
			}
		});

		updateRoster({
			...roster,
			players: [...roster.players, ...journeymanPlayers],
			treasury: roster.treasury - parseInt(formData.outOfPocketInducements),
		});
	};

	return (
		<FormHarness handleFormSubmit={handleUpdateRoster}>
			<Grid item xs={12}>
				<Typography variant="h6" sx={{ mb: 1 }}>
					Take-On Journeymen
				</Typography>
			</Grid>
			<Grid container spacing={1}>
				{needsJourneymen &&
					journeymanPositions.map((pos) => (
						<Grid item xs={12} md={6}>
							<TextField
								fieldName={pos.position}
								label={`Journeyman ${pos.position}`}
								fullWidth
								type="number"
								defaultValue={0}
								onChange={(e) => handleChangeJourneymen(e, pos)}
							/>
						</Grid>
					))}
				<Grid item xs={12}>
					<Typography>My Team Value: {teamValue.toLocaleString()}gp</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography sx={{ mb: 1 }}>
						Opponent's Team Value: {opponentRoster.value.toLocaleString()}gp
					</Typography>
					<Typography variant="caption">
						Note: Opponent's team value does not account for any journeymen they
						may add.
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h6" sx={{ mb: 1 }}>
						Inducements
					</Typography>
				</Grid>
				<Grid item xs={12} md={6}>
					<TextField
						fieldName="outOfPocketInducements"
						label="Treasury Gold Spent on Inducements"
						fullWidth
						type="number"
						defaultValue={0}
					/>
				</Grid>
			</Grid>
			<Button variant="contained" color="primary" fullWidth type="submit">
				Next
			</Button>
		</FormHarness>
	);
};

export default Pregame;
