import React from "react";
import { Container, Stack, Typography, Divider } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useCollectionDataOnce } from "react-firebase-hooks/firestore";
import { database } from "../../services/fire";
import Loading from "../../components/Loader";
import Brackets from "../../components/Brackets";
// import rankPlayers from "../../services/placings";
import { matches } from "../../constants/brackets";

const League = () => {
  const history = useHistory();
  const handleLink = (path) => {
    history.push(path);
  };

  const [rosters, rostersLoading, rostersError] = useCollectionDataOnce(
    database.collection("rosters3"),
    { idField: "id" }
  );

  const [players, playersLoading, playersError] = useCollectionDataOnce(
    database.collection("users")
  );

  if (rostersLoading || playersLoading) return <Loading />;
  if (rostersError || playersError)
    return <pre>An unexpected error occurred.</pre>;

  //   const rankedRosters = rankPlayers(rosters);
	
	console.log(rosters);

  return (
    <Container maxWidth="md" sx={{ padding: { xs: 0, sm: "inherit" } }}>
      <Brackets matches={matches} rosters={rosters} players={players} />
    </Container>
  );

  /*
	return (
		<Container maxWidth="md" sx={{ padding: { xs: 0, sm: 'inherit' } }}>
			<Stack direction="column" sx={{ mb: 2 }} spacing={1}>
				{rankedRosters.map((roster) => {
					const player = players.find((player) => player.uid === roster.owner);
					const { division } = Object.values(PLAYERS).find(
						(player) => player.id === roster.owner
					);
					return (
						<Stack
							direction="column"
							sx={{ flexBasis: '33%', cursor: 'pointer' }}
							onClick={() => handleLink(`/team/${roster.id}`)}
							key={roster.owner}
						>
							<Typography>
								{player.fname} {player.lname}
							</Typography>
							<Typography variant="caption">
								{roster.teamName} ({division})
							</Typography>
							<Typography variant="caption" mb={1}>
								{roster.rank} place
							</Typography>
							<Divider />
						</Stack>
					);
				})}
			</Stack>
		</Container>
	);
	*/
};

export default League;
